/* Icon X */
#nav-icon-x {
    width: 30px;
    height: 30px;
    position: relative;
    display:inline-block;
    margin: 15px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    cursor: pointer;
  }

  #nav-icon-x span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: RGB(226,226,226);
    border-radius: 2px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
/* Icon X */

#nav-icon-x span:nth-child(1) {
top: 0px;
}

#nav-icon-x span:nth-child(2),#nav-icon-x span:nth-child(3) {
top: 10px;
}

#nav-icon-x span:nth-child(4) {
top: 20px;
}

#nav-icon-x.open span:nth-child(1) {
top: 10px;
width: 0%;
left: 50%;
}

#nav-icon-x.open span:nth-child(2) {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}

#nav-icon-x.open span:nth-child(3) {
-webkit-transform: rotate(-45deg);
-moz-transform: rotate(-45deg);
-o-transform: rotate(-45deg);
transform: rotate(-45deg);
}

#nav-icon-x.open span:nth-child(4) {
top: 10px;
width: 0%;
left: 50%;
}
/* end Icon X */