/*  */

#bell {
  width: 100px;
  fill: #202020;
  position: relative;
  top: 5px;
  width: 35px;
}

#bell_anim {
  width: 100px;
  fill: #202020;
  -webkit-animation: bell 2s linear;
          animation: bell 2s linear;
  position: relative;
  top: 5px;
  width: 35px;
}

#bell_anim .animate {
  -webkit-animation: bell 2s linear;
  animation: bell 2s linear;
}

#bell_anim .clapper {
  -webkit-animation: clapper 500ms linear 4;
          animation: clapper 500ms linear 4;
}

@-webkit-keyframes clapper {
  0%, 100% {
    transform: none;
  }
  33% {
    transform: translate(-40%);
  }
  66% {
    transform: translate(40%);
  }
}

@keyframes clapper {
  0%, 100% {
    transform: none;
  }
  33% {
    transform: translate(-40%);
  }
  66% {
    transform: translate(40%);
  }
}
@-webkit-keyframes bell {
  20%, 80% {
    transform: rotate(25deg);
  }
}
@keyframes bell {
  20%, 80% {
    transform: rotate(25deg);
  }
}

.UserNotificationBox {
  position: absolute;
  top: 51px;
  right: 10px;
  /* background-color: var(--body_backcolor); */
  background-color:   #ffffff;
  border-left: 1px solid var(--border_backcolor);
  /* height: calc(100vh - 50px); */
  height: 500px;
  border-radius: 12px;
  width: calc(var(--menu_user_width) - 10px);
  z-index: 5;
  filter: blur(0px);
  overflow-y: auto;
  overflow-x: hidden;
}

.UserNotificationBox .txt_label_N1 { 
  position: relative;
  font-weight: 400;
  top: 5px;
  left: 20px;
  font-size: 12px;  
  color: #757575;
}

.UserNotificationBox .txt_label_N2 { 
  position: relative;
  font-weight: 400;
  top: 5px;
  left: 20px;
  font-size: 12px;  
  color: #2196F3;
  cursor: pointer;
  width: 60%;
}

.UserNotificationBox .txt_label_N3 { 
  position: absolute;
  font-weight: 400;
  font-size: 12px;  
  color: #2196F3;
  cursor: pointer;
  right: 20px;
  bottom: 26px;
}

.card_invite {
  width: 88%;
  height: 110px;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  text-align: center;
  position: relative;
  background-color: rgb(243, 243, 243);
  cursor: pointer;
}

.card_invite .inviteSI{
  position: relative;
  left: 20px;
  font-weight: 600;
  font-size: 20px;
  width: 80%;
  color:white;
  background-color:rgb(69, 212, 59);
  border-radius: 30px;
  text-align: center;
  margin: 8px;
  padding: 8px;
}

.card_invite .inviteNO{
  position: relative;
  left: 20px;
  font-weight: 600;
  font-size: 20px;
  width: 80%;
  color:white;
  background-color:rgb(219, 36, 36);
  border-radius: 30px;
  text-align: center;
  margin: 8px;
  padding: 8px;
}



.card_notification {
  width: 88%;
  height: 110px;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  position: relative;
  background-color: rgb(243, 243, 243);
  cursor: pointer;
}

.card_notification:hover {
  box-shadow: 0 2px 3px 0 rgba(100, 100, 100, 0.2);
  background-color: rgb(231, 231, 231);
}

.card_notification .txt_label_00 { 
  position: absolute;
  font-weight: 600;
  top: 20px;
  left: 20px;
  font-size: 32px;  
  color: #292929;
}

.card_notification .txt_label_01 { 
  position: absolute;
  /* font-weight: 600; */
  top: 30px;
  left: 70px;
  height: 70%;
  width: 70%;
  font-size: 14px;  
  color: #292929;
}

.card_notification .txt_label_02 { 
  position: absolute;
  font-weight: 400;
  top: 5px;
  right: 10px;
  font-size: 12px;  
  color: #2929295e;
}

.card_notification .txt_label_03 { 
  position: absolute;
  font-weight: 400;
  top: 5px;
  left: 10px;
  font-size: 12px;  
  color: #2929295e;
}

.card_notification .txt_label_04 { 
  position: absolute;
  font-weight: 400;
  bottom: 10px;
  left: 10px;
  font-size: 12px;  
  color: #2929295e;
}

.card_notification .txt_label_05 { 
  position: absolute;
  font-weight: 600;
  bottom: 8px;
  right: 10px;
  font-size: 14px;  
  color: #2196F3;
}

/*  */
.card_project {
    box-shadow: 0 2px 3px 0 rgba(100, 100, 100, 0.2);
    width: auto;
    height: 90px;
    border-radius: 8px;
    margin: 10px;
    /* padding: 10px; */
    position: relative;
    background-color: var(--card_backcolor);
 }

 .card_project_new {
  box-shadow: 0 2px 3px 0 rgba(100, 100, 100, 0.2);
  width: 85%;
  height: 55px;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  position: relative;
  background-color: #FCB322;
}
.item0 { 
  position: absolute;
  font-weight: 600;
  margin-top: 15px;
  left: 50px;
  font-size: 18px;  
  color: #ffffff;
}

.item1 { 
  position: absolute;
  font-weight: 600;
  top: 10px;
  left: 100px;
  font-size: 18px;  
  color: var(--card_text_color);
}
.item2 { 
  grid-area: logo; 
  width: 20%;
  padding: 10px;
  position: absolute;
  left: 10px;
  top: 5px;
  color: var(--card_text_color);
}
.item3 { 
  position: absolute;
  margin-top: 3px;
  left: 100px;
  top: 30px;
  font-size: 14px;  
  color: var(--card_text_color);
}
.item4 { 
  position: absolute;
  font-weight: 600;
  margin-top: 3px;
  left: 100px;
  top: 50px;
  font-size: 14px;
  color: var(--card_text_color);
}
.item5 { 
  position: absolute;
  margin-top: 3px;
  left: 10px;
  top: 80px;
  font-size: 14px;
  color: var(--text_color_form);
}
.item6 { 
  position: absolute;
  margin-top: 3px;
  left: 100px;
  top: 80px;
  font-size: 14px;
  color: var(--text_color_form);
}

.item7 { 
  position: absolute;
  margin-top: 3px;
  right: 15px;
  top: 80px;
  font-size: 14px;
  color: var(--text_color_form);
}

.GrLabelProject{
  position: relative;
  margin: 3px;
  padding: 5px;
  height: 10px;
  width: 85%;
}

.label_001{
  position: absolute;
  left: 8px;
  top: 5px;
  font-size: 12px;
  color: var(--text_color_menu);
}

.label_002{
  position: absolute;
  font-weight: 600;

  right: 0px;
  top: 5px;
  font-size: 14px;
  color: var(--text_color_menu);
}

.smile{
  position: absolute;
  bottom: 130px;
  right: 100px;
}

.face {
  position: absolute;
  width: 64px;
  height: 64px;
  background: #FCFCFC;
  border-radius: 50%;
  border: 1px solid #777777;
  top: 5px;
  left: 5px;
  z-index: 2;
  animation: bounce 1s ease-in infinite;
}

.smile_ava{
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.eye {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #777777;
  border-radius: 50%;
  top: 40%;
  left: 20%;
}

.right {
  left: 68%;
}

.mouth {
  position: absolute;
  top: 43%;
  left: 41%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.happy {
  border: 2px solid;
  border-color: transparent #777777 #777777 transparent;
  transform: rotate(45deg);
}
.shadow {
  position: absolute;
  width: 64px;
  height: 15px;
  opacity: 0.5;
  background: #777777;
  left: 5px;
  top: 65px;
  border-radius: 50%;
  z-index: 1;
}

.scale {
  animation: scale 1s ease-in infinite;
}

@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}
@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}

/* .card:hover {
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.2);
} */


.menu-dot {
  background-color: var(--placeholder-color);
  box-shadow: -6px 0 0 0 var(--placeholder-color), 6px 0 0 0 var(--placeholder-color);
  width: 4px;
  height: 4px;
  border: 0;
  padding: 0;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 8px;
}
.job-card-title {
  font-weight: 600;
  margin-top: 16px;
  font-size: 14px;
}
.job-card-subtitle {
  color: var(--subtitle-color);
  font-size: 13px;
  margin-top: 14px;
  line-height: 1.6em;
}
.job-card-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
}




  .policy {
    position: absolute;
    left: 15px;
    bottom: 20px;
    font-size: 10px;
    text-align: center;
    color: var(--text_color_menu);
  }
  .prvt {
    position: absolute;
    left: 15px;
    bottom: 5px;
    font-size: 10px;
    text-align: center;
    color: var(--text_color_menu);
  }

  div.radio-box {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 15px;
  }
  
  input[name="radio-01"]{display: none}
  
  input[name="radio-01"]+label {
    width: 90px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  
  /* - - - radio 01 - - - */
  input[name="radio-01"]+label {
    transition: all 200ms cubic-bezier(.4,.25,.3,1);
    padding: 20px 0;
    color: var(--text_color_menu);
    background-color: transparent;
    border: 2px solidvar(--text_color_menu);
  }
  input[name="radio-01"]+label:hover {opacity: .65}
  
  input[name="radio-01"]+label:active {
    transition: none;
    transform: scale(.925);
  }
  
  input[name="radio-01"]:checked+label,
  input[name="radio-01"]:checked+label:hover {
    background-color: var(--body_backcolor);
    color: #F97251;
    opacity: 1;
    font-weight: bold;
  }
  
  .example-side-bar-list-down {
    padding: 0;
    margin: 0;
    list-style-type: none;
    position:fixed ;
    bottom: 0px;
    left: 0px;
    /* display: flex;
    flex-direction: column;
    justify-content: flex-end; */
    /* min-height: 70%; Ajusta la altura del contenedor según tus necesidades */
  }

  .example-side-bar-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    position:fixed ;
    left: 0px;
  }

  .hovers {
    border-right: #F97251 side 3px;
  }

  .example-side-bar-link {
    height: 3rem;
    display: flex;
    flex-shrink: 0;
    flex-wrap: nowrap;
    align-items: center;
    transition: background-color 32ms ease-out;
  }

  .example-side-bar-link:hover {
    background-color: #818181;
  }

  .custom-check{
    padding: 10px;
    color: var(--text_color_menu);
  }

.appbar {
    position: fixed;
    display: inline-block;
    top: 0px;
    left: 0px;
    background-color: var(--menu_backcolor);
    border-bottom: 1px solid var(--border_backcolor);
    height: 50px;
    width: 100%;
    z-index: 3;
    user-select: none;
}


.navbar_username{
    position: absolute;
    margin: 5px;
    right: 55px;
    top: 3px;
    color: var(--text_color_menu);
    font-size: 12px;
    font-weight: 600;
}
.navbar_usercompany{
    position: absolute;
    margin: 5px;
    right: 55px;
    top: 20px;
    color: var(--text_color_menu);
    font-size: 12px;
}



.app_user_card{
    position: relative;
    width: 100%;
    text-align: center;
}

.AppMenuBtm {
    position: absolute;
    top: 10px;
    left: 0px;
    width: 100%;
    height: auto; 
}

.btnboxmenu{ 
    background: var(--btn-col);
    /*--switch: calc((var(--btn-col) - var(--text-color)) * -100);*/
    /*color: hsl(0, 0%, var(--switch));*/
    font-size: 20px;
    text-align: center;
    text-transform:uppercase;
    outline: none;
    width: 90%;
    height: 50px;
    border: 0;
	padding: 10px 20px;
    margin-top:5px;
    margin-left: 15px;
    margin-right: 5px;
    margin-bottom:5px;
	border-radius:3px;
	cursor:pointer;
    position:relative;
    opacity: 1;
}

.cardEvent {
    width: 170px;
    height: 110px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 15px 15px #f7f7f7;
    padding-left: 23px;
    padding-top: 20px;
    box-sizing: border-box;
  }

.btnboxcard{ 
	color:#fff;
	background:linear-gradient(60deg, rgb(192, 130, 15) 0%, rgb(255, 166, 0) 63%, rgb(199, 121, 31) 100%);
    outline: none;
    width: 170px;
    height: 110px;
    border: 0;
	padding:10px 20px;
	text-transform:uppercase;
    text-align: center;
    margin-top:5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:5px;
	border-radius:3px;
	cursor:pointer;
    position:relative;
    opacity: 1;
    box-sizing: border-box;
}

.btnboxcard:hover , .btn:hover {
    background:linear-gradient(60deg, rgb(255, 166, 0) 0%, rgb(255, 185, 56) 63%, rgb(255, 136, 0) 100%);
  }

.btn{ 
	color:#fff;
	background-color:#FCB322;
    outline: none;
    width: 100%;
    height: 35px;
    border: 0;
	padding:10px 20px;
	text-transform:uppercase;
    margin-top:5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:5px;
	border-radius:3px;
	cursor:pointer;
    position:relative;
    opacity: 1;
}

.textcampo {
    position: relative;
    margin: 5px;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 95%;
    color: var(--column-bg,#2C3E50);
    font-size: 16px;
}

.react-datepicker-wrapper{
    position: relative;
    width: 100%;
}
.app_user_card .userc_ava{
    position: absolute;
    top: 10px;
    left: 10px;
    width: 64px;
    border-radius: 20%;
    filter: brightness(90%);
    /*box-shadow: 0px 0px 7px rgb(255, 255, 255);*/
}

.app_user_card .userc_name {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 100px;
    margin:auto;
  }

  .app_user_card .userc_status {
    color: var(--btn-col,#FCB322);
    font-size: 14px;
    position: absolute;
    top: 35px;
    left: 100px;
    margin:auto;
  }

  .menu_list {
    position: absolute;
    width: 100%;
    height: 100px;
    left: 0px;
    top: 200px;
  }

  .app_user_rank {
    background-color: rgba(10, 10, 10, 1);
    position: absolute;
    width: 100%;
    height: 100px;
    left: 0px;
    top: 100px;
  }

  .app_user_rank .userc_rank{
    color: var(--text-color,#FCB322);
    background-color: rgba(25, 25, 25, 1);
    font-size: 25px;
    position: initial;
    text-align: center;
    list-style-type: none;
    width: 80px;
    height: 50px;
    left: 100px;
    margin:8px;
    padding: 13px 0;
    display: inline-block;
  }
  .app_user_rank .userc_rank_h1{
    color: var(--text-color,#FCB322);
    font-size: 14px;
    position: absolute;
    text-align: center;
    width: 80px;
    left: 20px;
    top: 50px;
    border: inset 0px red;
    margin:8px;

  }

  .app_user_rank .userc_rank_h2{
    color: var(--text-color,#FCB322);
    font-size: 14px;
    position: absolute;
    text-align: center;
    width: 80px;
    left: 115px;
    top: 50px;
    border: inset 0px red;
    margin:8px;

  }

  .app_user_rank .userc_rank_h3{
    color: var(--text-color,#FCB322);
    font-size: 14px;
    position: absolute;
    text-align: center;
    width: 80px;
    left: 210px;
    top: 50px;
    border: inset 0px red;
    margin:8px;

  }

  
  .UserMenuBtm {
    position: absolute;
    top: 220px;
    left: 0px;
    width: 100%;
    height: auto;
}

.menuhide{
    position: relative;
    visibility: hidden;
    opacity:0; /*Элемент полностью прозрачный (невидимый)*/
    left: 0px;
    top: -65px;
    transition: .3s; /*Скорость перехода состояния элемента*/
    animation-name: menuout;
    animation-duration: .3s;
}

.menuopen{
    position: relative;
    visibility: visible;
    left: 0px;
    top: -65px;
    opacity:1; /*Элемент полностью прозрачный (невидимый)*/
    transition: .3s; /*Скорость перехода состояния элемента*/
    animation-name: menuin;
    animation-duration: .3s;
}

.UserMenuBox {
    position: absolute;
    top: 51px;
    right: 0px;
    background-color: var(--menu_backcolor);
    border-left: 1px solid var(--border_backcolor);
    height: calc(100vh - 50px);
    width: var(--menu_user_width);
    z-index: 5;
    filter: blur(0px);
    overflow-y: auto;
}

.UserMenuBox3 {
    position: absolute;
    top: 51px;
    right: 0px;
    background-color: var(--menu_backcolor);
    border-left: 1px solid var(--border_backcolor);
    height: calc(100vh - 50px);
    width: var(--menu_opt_width);
    z-index: 3;
    filter: blur(0px);
}

.SadminMenuBox {
    position: absolute;
    top: 50px;
    right: 0px;
    background-color: rgba(0,0,0,0.9);
    height: 100ch;
    width: var(--menu_user_width);
    filter: blur(0px);
    z-index: 10;
}

.menu2hide{
    position: relative;
    visibility: hidden;
    opacity:0; /*Элемент полностью прозрачный (невидимый)*/
    right: 0px;
    top: -65px;
    transition: .3s; /*Скорость перехода состояния элемента*/
    animation-name: menu2out;
    animation-duration: .3s;
}

.LSmenuhide{
  position: relative;
  visibility: hidden;
  opacity:0; /*Элемент полностью прозрачный (невидимый)*/
  left: 0px;
  top: -65px;
  transition: .3s; /*Скорость перехода состояния элемента*/
  animation-name: menuout;
  animation-duration: .3s;
}

.LSmenuopen{
  position: relative;
  visibility: visible;
  left: 0px;
  top: -65px;
  opacity:1; /*Элемент полностью прозрачный (невидимый)*/
  transition: .3s; /*Скорость перехода состояния элемента*/
  animation-name: menuin;
  animation-duration: .3s;
}

.menu2open{
    position: relative;
    visibility: visible;
    right: 0px;
    top: -65px;
    opacity:1; /*Элемент полностью прозрачный (невидимый)*/
    transition: .3s; /*Скорость перехода состояния элемента*/
    animation-name: menu2in;
    animation-duration: .3s;
}

@keyframes menu2in {
    0%   {left:300px; top:-65px;}
    100%  {left:0px; top:-65px;}
  }

  @keyframes menu2out {
    0%   {left:0px; top:-65px;}
    100%  {left:300px; top:-65px;}
  }


@keyframes menuin {
    0%   {left:-300px; top:-65px;}
    100%  {left:0px; top:-65px;}
  }

  @keyframes menuout {
    0%   {left:0px; top:-65px;}
    100%  {left:-300px; top:-65px;}
  }

.h1welcom{
    color: white;
    width: 90%;
}

.AppMenuBox {
    position: absolute;
    top: 51px;
    left: 0px;
    background-color: var(--menu_backcolor);
    border-right: 1px solid var(--border_backcolor);
    height: calc(100vh - 50px);
    /* height: calc(100vh - 50px); */
    width: var(--menu_left_width);
    z-index: 2;
    filter: blur(0px);
    overflow-y: none;
    z-index: 3;
}

.LSMenuBox {
  position: absolute;
  top: var(--top_body);
  left: var(--menu_left_width);
  background-color: var(--menu_backcolor);
  border-right: 1px solid var(--border_backcolor);
  height: calc(100vh - 50px);
  /* height: calc(100vh - 50px); */ 
  width: var(--menu_user_width); 
  z-index: 2;
  filter: blur(0px);
  overflow-y: auto;
}


.appcard {
    position: absolute;
    /*display: inline-block;*/
    top: 65px;
    left: 15px;
    /*background-color: rgba(0,0,0,0.2);*/
    height: 90%;
    width: 100%;
}

.mycompbutcontl {
    position: absolute;
    /*display: inline-block;*/
    top: 80px;
    left: 5px;
    /*background-color: rgba(0,0,0,0.2);*/
    height: 90%;
    width: 100%;
}

.sadmin {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    margin: 5px 0px 0px 0px;
    right: 55px;
    width: 40px;
    height: auto;
    border-radius: 5px;
    z-index: 999;
}

.comentava {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    margin: 5px 0px 0px 0px;
    right: 75px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    z-index: 999;
}

.appava_msg {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  margin: 5px 0px 0px 0px;
  right: 65px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  z-index: 9999;
  top: 0px;
  font-size: 28px;
  color: RGB(226,226,226);
  text-align: center;
  /* filter:invert(var(--img_invert)) */
}

.num-count {
  position: absolute;
  user-select: none;
  cursor: default;
  font-size: 0.6rem;
  background: #e74c3c;
  width: 1.2rem;
  height: 1.2rem;
  color: #ecf0f1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 0;
  right: 0;
  box-sizing: border-box;
}


.appava_msg:hover {
  color: #FCB322;
}

.appava {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    margin: 5px 0px 0px 0px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    z-index: 9999;
    top: 0px;
    right: 10px;
    /* filter:invert(var(--img_invert)) */
}

.applogo {
    position: absolute;
    display: inline-block;
    margin: 10px 0px 0px 0px;
    width: 35px;
    height: auto;
    top: 0px;
    left: 65px;
}

.applogotxt {
  position: absolute;
  display: inline-block;
  margin: 10px 0px 0px 0px;
  left: 100px;
  width: 50px;
  height: auto;
  filter:invert(var(--img_invert));
  top: 0px;
  left: 110px;
}

.applogotext {
    position: absolute;
    display: inline-block;
    margin: 10px 0px 0px 0px;
    left: 90px;
    font-size: 24px;
    width: auto;
    color:  var(--text_color_menu);
    height: auto;
    text-transform:uppercase;
    top: 0px;
    left: 110px;
}



.iframeapp {
    padding: 15px;
    height: calc( 100% - 400px );
}


.facebook-login-btn:hover {
  background-color: #25417b;
  color: #FFF;
}
.facebook-login-btn {
  color: #FFF;
  background-color: #3b5998;
  border-radius: 0.4em;
  font-size: 1rem;
  padding: 0.75em 1em;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 1;
  text-align: center;
  font-weight: 300;
  display: inline-block;
  cursor: pointer;
  width: 80%;
  margin: 0.5em;
}

@media only screen and (max-width: 600px) {

    .btnboxcard{ 
        color:#fff;
        background-color:#FCB322;
        outline: none;
        width: 47%;
        height: 100px;
        border: 0;
        padding:10px 20px;
        text-transform:uppercase;
        margin-top:5px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom:5px;
        border-radius:3px;
        text-align: center;
        cursor:pointer;
        position:relative;
        opacity: 1;
    }

    .applogotext {
        position: absolute;
        display: inline-block;
        margin: 14px 0px 0px 0px;
        left: 90px;
        font-size: 24px;
        width: 200px;
        color: white;
        height: auto;
    }
}

.search-container {
    position: absolute;
    top: 2px;
    left: 300px;
    height: 32px;
    width: 400px;
    border: 1px solid rgb(172, 171, 179);
    border-radius: 3px;
    margin: 5px;
  }
  
  .search-container input[type=text] {
    position: relative;
    top: -4px;
    height: 14px;
    font-size: 14px;
    width: CALC(360px - 60px);
    outline: none;
    border: 0;
    padding: 0;
    padding-left: 5px;
    color: var(--text_color_menu);
    background-color: var(--menu_backcolor);

  }

 
  
.search-container button {
    float: right;
    padding: 6px 10px;
    margin-top: 8px;
    margin-right: 16px;
    background: #ddd;
    font-size: 14px;
    border: none;
    cursor: pointer;
  }

  .search-container .searchIco{
    position: relative;
    width: 16px;
    width: 16px;
    top: 2px;
    padding: 5px;
    filter:invert(var(--img_invert))
  }
  
  .search-container button:hover {
    background: #ccc;
  }

  .infoAppContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .infoAppContainer .row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .aLogo {
    width: 100px;
    position: relative;
    /* left: calc(100% / 3.5); */
    padding:0 22%;
    margin: 10px;
    /* flex-shrink: 0;
    margin: 0 10px */
    /* min-height: 100px; */
  }

  .addnotetext {
    position: absolute;
    width: 85%;
    font-size: 18px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    margin: 15px;
    background-color: #ffffff48;
    color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    font-weight: 400;
    top: 500px;
  }

  .addcodetext {
    position: absolute;
    width: 85%;
    font-size: 36px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    margin: 15px;
    background-color: #FFF;
    color: #000000;
    border-radius: 8px;
    padding: 15px;
    font-weight: 600;
    top: 650px;
  }

  .icon_qrscaner{
    position: absolute;
    top: 8px;
    right: 25px;
    width: 32px;
  }

  .qrLogo {
    position: absolute;
    top: 130px;
    margin: 15px;
    border-radius: 8px;
  }

  .QRurlApp{
    position: absolute;
    top: 25px;
    width: 80%;
    left: calc( 100% / 2);
  }


  .aButtonClose {
    position: absolute;
    top: 0px;
    right: 8px;
    font-size: 24px;
    cursor: pointer;
  }

  .aButtonSetings {
    position: absolute;
    top: 8px;
    left: 12px;
    font-size: 18px;
    cursor: pointer;
    z-index: 10;
  }

  .aButtonSetings:hover,  
  .aButtonClassBack:hover, 
  .aButtonClassAdd:hover,
  .aButtonClassImport:hover,
  .aButtonClassConfig:hover,
  .aButtonClassHome:hover {
    color: rgb(58, 138, 195);
  }

  .aButtonClassHome {
    position: absolute;
    top: 8px;
    left: 40px;
    font-size: 18px;
    cursor: pointer;
  }

  .aButtonClassBack {
    position: absolute;
    top: 0px;
    left: 12px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .aButtonExamenAdd {
    position: absolute;
    top: 8px;
    right: 240px;
    font-size: 18px;
    cursor: pointer;
  }

  .aButtonFileAdd {
    position: absolute;
    top: 8px;
    right: 190px;
    font-size: 18px;
    cursor: pointer;
  }

  

  .aButtonClassAdd {
    position: absolute;
    top: 0px;
    right: 140px;
    font-size: 18px;
    cursor: pointer;
  }

  .aButtonClassImport {
    position: absolute;
    top: 0px;
    right: 60px;
    font-size: 18px;
    cursor: pointer;
  }

  .aButtonClassDelete {
    position: absolute;
    top: 0px;
    right: 100px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .aButtonClassConfig {
    position: absolute;
    top: 0px;
    right: 20px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .tab-header{
    display: grid;
    height: 100%;
    width: 100%;
    overflow: hidden;
    grid-template-rows: 42px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .tab{
    cursor: pointer;
    border: 2px Solid rgb(58, 138, 195);
    text-align: center;
    padding: 5px;
    color: rgb(58, 138, 195);
    font-weight: 600;
    border-radius: 5px;
    font-size: 14px;
    margin: 5px;
  }

  .tab:hover {
    color: rgb(237, 239, 240);
    background-color: rgb(58, 138, 195);
  }

  .tab.active {
    color: rgb(237, 239, 240);
    background-color: rgb(58, 138, 195);
  }


  
  .blue-color {
    background-color: blue; /* Color de fondo azul */
    display: inline-block;
  }
  
  .loginQRbtn {
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    color: #FFF;
    font-weight: 500;
    border-radius: 5px;
    font-size: 14px;
    margin: 15px 0 0 0;
    position: relative;
    z-index: unset;
    background-color: rgb(58, 138, 195);
  }

  .loginQRbtn:hover {
    color: rgb(237, 239, 240);
    background-color: rgb(195, 120, 58);
  }

  .aButtonOpen {
    cursor: pointer;
    border: 2px Solid rgb(58, 138, 195);
    text-align: center;
    padding: 10px;
    color: rgb(58, 138, 195);
    font-weight: 600;
    border-radius: 5px;
    font-size: 14px;
    margin: 15px;
    position: relative;
    z-index: unset;
    left: 10px;
  }

  .aButtonOpen:hover {
    color: rgb(237, 239, 240);
    background-color: rgb(58, 138, 195);
  }

  .aButtonOpen.active {
    color: rgb(237, 239, 240);
    background-color: rgb(58, 138, 195);
  }

  .aTitle {
    font-size: 24px;
    font-weight: 600;
    margin: 15px;
    color: var(--text_color_menu);
  }

  .aNote {
    font-size: 14px;
    font-weight: 600;
    color: #8f8f8f;
    margin: 15px;
  }

  .aInfo {
    font-size: 14px;
    text-align: center;
    color: var(--text_color_menu);
    background-color: var(--body_backcolor);
    padding: 10px;
    border-radius: 5px;
    margin: 15px;
  }


  .scanner-container {
    height: 100vh;
    width: 100vw;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
  }
  .scanner-container .scanner {
    width: 80vw;
    height: 80vw;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(#fff, #fff) left top, linear-gradient(#fff, #fff) left top, linear-gradient(#fff, #fff) right top, linear-gradient(#fff, #fff) right top, linear-gradient(#fff, #fff) left bottom, linear-gradient(#fff, #fff) left bottom, linear-gradient(#fff, #fff) right bottom, linear-gradient(#fff, #fff) right bottom;
    background-repeat: no-repeat;
    background-size: 2px 24px, 24px 2px;
    z-index: 10;
  }

  .scanner-container .qrcamera {
    width: 80vw;
    height: 80vw;
    position: absolute;
    transform: translateX(-50%);
    top: 20%;
    left: 50%;
    opacity: 0.8;
  }

  .scanner-container .scanner:before {
    content: "";
    position: absolute;
    width: 95%;
    background: #00a001;
    top: 2.5%;
    left: 2.5%;
    height: 5px;
    border-radius: 100%;
    box-shadow: 0 0 4px 0 green;
    animation: scan ease-in-out 2s infinite;
  }

  .TabCheck {
    position: absolute;
    top: 70px;
    left: 15px;
    width: 90%;
    height: 32px;
    padding: 8px;
    margin: 0px;  
    display: inline;
  }

  .tab-group {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
    display: block;
  }

  .tab-group .tabbtn {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
  }

  

  .tab-group .a {
    display: block;
    text-decoration: none;
    padding: 10px;
    font-size: 20px;
    float: left;
    width: 43%;
    text-align: center;
    cursor: pointer;
    transition: .5s ease;
    border-radius: 8px 0 0 8px;
  }

  .tab-group .b {
    display: block;
    text-decoration: none;
    padding: 10px;
    font-size: 20px;
    float: left;
    width: 43%;
    text-align: center;
    cursor: pointer;
    transition: .5s ease;
    border-radius: 0 8px 8px 0;
  }

  .active{
    background: #FCB322;
    color: #000000;
  }

  .pasive{
    background: rgba(160, 179, 176, 0.25);
    color: #a0b3b0;
  }

  .NickNameInput {
    font-size: 22px;
    display: block;
    padding: 10px;
    width: 92%;
    background: none;
    background-image: none;
    border: 1px solid #a0b3b0;
    color: #ffffff;
    border-radius: 0;
    transition: border-color .25s ease, box-shadow .25s ease;
    margin: 5px 0 0 0;
    text-align: center;
}

.labelNickName{
  color: #FFF;
}

  @keyframes scan {
    0% {
      transform: translateY(0);
      opacity: 0.3;
    }
    15% {
      opacity: 0.9;
    }
    35% {
      opacity: 0.9;
    }
    50% {
      transform: translateY(75vw);
      opacity: 0.5;
    }
    65% {
      opacity: 0.9;
    }
    85% {
      opacity: 0.9;
    }
    100% {
      transform: translateY(0);
      opacity: 0.3;
    }
  }


  @media (max-width: 700px) {
    .search-container, .navbar_username, .navbar_usercompany, .transbox .company, .transbox .users{
      display: none;
    }

    .transbox .projectlogo {
      position: absolute;
      left: 15px;
    }

    .transbox .label, .transbox .name, .transbox .note {
      position: absolute;
      left: 130px;
      width: 65%;
    }

    .project_header .notes{
      position: absolute;
      top:  135px;
      left: 145px;
    }

    
  }
