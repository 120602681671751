/* Estilos generales */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Sombra */
  }
  
  .modal h2 {
    margin-top: 0;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    margin-left: 10px;
    padding: 8px 20px;
    border: none;
    cursor: pointer;
    background-color: #007bff; /* Azul */
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .modal-buttons button:hover {
    background-color: #0056b3; /* Azul más oscuro al pasar el ratón */
  }
  