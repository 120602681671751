.card-0 {
    color: white;
    background: #fff;
  }

.card_parte {
    cursor: pointer;
    width: 90%;
    height: 160px;
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    position: relative;
  }

  .card_parte .parte-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px; /* Ajusta el ancho según sea necesario */
    background-color: #ee7d14; /* Azul oscuro similar al de la imagen */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px 0 0 10px;
  }

  .card_parte .airbnb-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px; /* Ajusta el ancho según sea necesario */
    background-color: #FF385C; /* Azul oscuro similar al de la imagen */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px 0 0 10px;
  }

  .card_parte .booking-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px; /* Ajusta el ancho según sea necesario */
    background-color: #002868; /* Azul oscuro similar al de la imagen */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px 0 0 10px;
  }
  
  .card_parte .booking-text {
    color: white;
    font-size: 16px; /* Ajusta el tamaño de la fuente */
    font-family: Arial, sans-serif; /* O la fuente que prefieras */
    transform: rotate(-90deg);
    letter-spacing: 10px;
  }


  .card_parte .sUser {
    position: absolute;
    padding: 8px;
    width: 25px;
    height: 25px;
    top: 85px;
    left: 35px;
    border-radius: 30%; /* Встановіть радіус закруглення на половину ширини або висоти, щоб отримати круглу форму */
    object-fit: cover; /* Забезпечте, щоб фото повністю покривало область, задану шириною та висотою */
    filter: invert(1);
  }

  .card_parte .sNoche {
    position: absolute;
    padding: 8px;
    width: 20px;
    height: 20px;
    top: 85px;
    left: 145px;
    border-radius: 30%; /* Встановіть радіус закруглення на половину ширини або висоти, щоб отримати круглу форму */
    object-fit: cover; /* Забезпечте, щоб фото повністю покривало область, задану шириною та висотою */
    filter: invert(1);
  }

  .card_parte .sInICO {
    position: absolute;
    padding: 8px;
    width: 20px;
    height: 20px;
    top: 30px;
    left: 40px;
    border-radius: 30%; /* Встановіть радіус закруглення на половину ширини або висоти, щоб отримати круглу форму */
    object-fit: cover; /* Забезпечте, щоб фото повністю покривало область, задану шириною та висотою */
    filter: invert(1);
  }

  .card_parte .sOutICO {
    position: absolute;
    padding: 8px;
    width: 20px;
    height: 20px;
    top: 55px;
    left: 40px;
    border-radius: 30%; /* Встановіть радіус закруглення на половину ширини або висоти, щоб отримати круглу форму */
    object-fit: cover; /* Забезпечте, щоб фото повністю покривало область, задану шириною та висотою */
    filter: invert(1);
  }

  .card_parte .sTitle {
    position: absolute;
    top: 8px;
    left: 45px;
    font-weight: 600;
    font-size: 18px;
    color: var(--text_color_form);
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
}

.card_parte .sEmail {
  position: absolute;
  top: 35px;
  left: 80px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}

.card_parte .sA1 {
  position: absolute;
  top: 54px;
  left: 80px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}

.card_parte .sA2 {
  position: absolute;
  top: 54px;
  left: 130px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}

.card_parte .sA3 {
  position: absolute;
  top: 54px;
  left: 190px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}

.card_parte .sId {
  position: absolute;
  bottom: 5px;
  left: 45px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}

.card_parte .sStatus {
  position: absolute;
  top: 85px;
  left: 85px;
  font-weight: 400;
  font-size: 24px;
  color: var(--text_color_form);
}

.card_parte .sNoches {
  position: absolute;
  top: 85px;
  left: 185px;
  font-weight: 400;
  font-size: 24px;
  color: var(--text_color_form);
}


.card_parte .sDateIn {
  position: absolute;
  top: 37px;
  left: 75px;
  font-weight: 400;
  font-size: 14px;
  color: var(--text_color_form);
}

.card_parte .sDateOut {
  position: absolute;
  top: 62px;
  left: 75px;
  font-weight: 400;
  font-size: 14px;
  color: var(--text_color_form);
}


/* .day {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
} */


.calendar-container {
  text-align: center;
  margin: 20px;
}

.navigation button {
  margin: 10px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.months {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 колонки */
  gap: 20px; /* Простір між колонками */
  justify-items: center; /* Вирівнювання елементів по центру */
  margin-top: 20px;
}

.month {
  display: inline-block;
  width: 100%; /* Займає всю ширину в колонці */
  border: 1px solid #ccc;
  padding: 35px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-sizing: border-box;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  text-align: center;
}

.day {
  /* border: 1px solid #ccc; */
  padding: 10px;
  /* background-color: #fff; */
  border-radius: 50%;

  color: #424588;
  font-size: 12px;
  font-weight: 500;
  width: 56%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.day:hover {
  background: #898a8a;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  transition: 0.2s all ease-in;
}

.occupied {
  color: #fff;
}



.containerx {
  display: flex;
  gap: 10px;
}

.buttonx {
  width: 60px; /* Ширина елемента */
  height: 60px; /* Висота елемента */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; /* Розмір шрифту */
  font-weight: bold;
  color: black;
  background: linear-gradient(135deg, 
  #d9eaff 50%, /* Світлий блакитний */
  #f2f2f2 50%); /* Світлий сірий */  
  border-radius: 10px; /* Заокруглення кутів */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Тінь */
  border: 1px solid #ccc; /* Легка рамка */
}

.buttonx:nth-child(odd) {
  background: linear-gradient(135deg, 
    #b0d4ff 45%,  /* Блакитний */
    #e6e6e6 45%,  /* Сірий */
    #f0f0f0 55%,  /* Фіолетовий */
    #b8a8ff 55%); /* Світло-сірий */
}

.reservasgroup{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px; 
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-items: center; /* adjusted */
}

@media (max-width: 5000px) {
  .reservasgroup{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
    grid-gap: 15px; 
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    justify-items: center; /* adjusted */
  }
}

@media (max-width: 3000px) {
  .reservasgroup{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px; 
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    justify-items: center; /* adjusted */
  }
}

@media (max-width: 2700px) {
  .reservasgroup{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    grid-gap: 15px; 
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    justify-items: center; /* adjusted */
  }
}

@media (max-width: 2400px) {
  .reservasgroup{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    grid-gap: 15px; 
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    justify-items: center; /* adjusted */
  }
}

@media (max-width: 1700px) {
  .reservasgroup{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px; 
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    justify-items: center; /* adjusted */
  }
}

@media (max-width: 1200px) {
  .reservasgroup{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px; 
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    justify-items: center; /* adjusted */
  }
}

@media (max-width: 900px) {
.reservasgroup{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px; 
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-items: center; /* adjusted */
}}

@media (max-width: 600px) {
  .reservasgroup{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-gap: 15px; 
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
    justify-items: center; /* adjusted */
  }
}