:root {
  --brand-hue: 200;
  --menu_left_width: 60px;
  --menu_user_width: 320px;
  --menu_opt_width: 320px;
  --menu_backcolor: rgb(255,255,255);
  --body_backcolor: rgb(245,245,245);
  --border_backcolor: rgb(225,225,225);
  --text_color_menu: rgb(44, 62, 80);
  --text_color_form: rgb(44, 62, 80);
  --card_backcolor: rgb(255,255,255);
  --card_text_color: rgb(215,130,52);
  --img_invert: 0%;
  --max_colums: auto-fill;
  --size_card: 135px;
  --size_grooup_card: 100px;

  --space_card: 5%;
  --top_body: 75px;
  --color-canvas-default: rgb(245,245,245);
  /* --size_card: calc((100vw) / (var(--size_colums) + 5) ); */
  /* --grid_size_card: calc(var(--size_card) + 6%); */
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    /*-moz-osx-font-smoothing: auto;*/
    transition: 0; /*Скорость перехода состояния элемента*/
    background-color: var(--body_backcolor);
  }


.body {
    position: absolute;
    top:  var(--top_body);
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100% - var(--top_body));
    /* overflow-y: hidden; */
    overflow-x: hidden;
    transition: .3s; /*Скорость перехода состояния элемента*/
    animation-name: menuin;
    animation-duration: .0s;
}

.bodyApp {
  position: absolute;
  top:  0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100% ;
  /* overflow-y: hidden; */
  overflow-x: hidden;
  transition: .3s; /*Скорость перехода состояния элемента*/
  animation-name: menuin;
  animation-duration: .0s;
  /* background-color: #00000096; */
}

.FirstRunText{
  color:var(--menu_backcolor) ;
}

.InfoChoisClass{
  position: relative;
  top: 28px;
  font-size: 18px;
  color:var(--menu_backcolor) ;
  padding: 25px;
  background-color: #2e2e2e;
  border-radius: 12px;
  margin: 25px;
}

.InfoFinCardClass{
  position: relative;
  top:  140px;
  font-size: 16px;
  color:var(--menu_backcolor) ;
  padding: 25px;
  background-color: #2e2e2e;
  border-radius: 12px;
  margin: 25px;
}


.BoxMainMenu{
  position: fixed;
    display: flex;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 80px;
    color: var(--card_text_color);
    background-color: #0b0b0b8f;
}

.BoxMainMenu .Box1 {
  position: relative;
  width: 20%;
  font-size: 25px;
  color: var(--menu_backcolor);
  text-align: center;
  padding: 15px;
}

.BoxMainMenu .Box1:hover {
  transform: scale(1.20);
  color: #ff9500;
}

.BoxMainMenu .select {
  transform: scale(1.20);
  border-top: 3px solid #ff9500;
}

.BoxMainMenu .select, .BoxMainMenu .select .iconesmenus {
  transform: scale(1.20);
  border-top: 3px solid #FF7C33;
  /* filter: drop-shadow(0px 100px 0 #ff9500);
  transform: translateY(-100px); */
  /*background-color: #ff9500; /* Змінити на потрібний колір */
  /*pointer-events: none; /*Щоб накладання не блокувало взаємодію з зображенням */
}


.AppLoginLogo {
  width: 200px;
  height: 200px;
  padding: 10px;
}

.AppWrapper {
  margin: 0 auto;
  padding: 62px 0 15px;
  width: 300px;
  height: calc(100%) ;
}

.AppWelcom{
  color: var(--menu_backcolor);
  text-align: center;
  font-weight: 300;
  font-size: 45px;
  padding-bottom: 25px;
}


.AppFooter{
  position: absolute;
  bottom:  30px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
  padding: 0px;
  color: var(--menu_backcolor);
}

.AppFooter ul {
  list-style-type: none;
  padding: 0px;
}
.AppFooter li {
  display: inline-block;
  margin: 0 25px;
}
.AppFooter a {
  color: #323232;
  text-decoration: none;
}
.AppFooter a:hover {
  color: #0366D6;
  text-decoration: underline;
}


aside {
  height: 165px;
  width: auto;
}

aside h3 {
  position: relative;

  top: 0;
  left: -150px;

  transform-origin: top right;
  transform: rotate(-90deg);
  text-align: right;

  font: bold 12px Sans-Serif;
  letter-spacing: 3px;
  text-transform: uppercase;
  background: #369;
  color: #fff;
  width: 135px;
  height: 16px;
  padding: 10px;
}

.project_urls{
  position: relative;
  top:  0px;
  left: 0px;
  width: calc(100% - (20px));
  /* height:  30px; */
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  color: var(--text_color_menu);
  background-color: rgba(203, 203, 203, 0.203);
}

.class_header{
  position: relative;
  top:  0px;
  left: 0px;
  width: calc(100% - (20px));
  height:  130px;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  color: var(--text_color_menu);
  background-color: rgba(203, 203, 203, 0.203);  
}

.teachers {
  display: flex;
  justify-content: center;
}


.teacher {
  left: 50%;

  width: 30%;
  height: auto;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  border-radius: 10px;
  padding: 0px;
  margin: 0px;
  color: #FFF;
}

.teacher .avatar {
  top:  0;
  left: 0;
  width: 300px;
  height: auto;
  /* background-color: #00000052; */
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  user-select: none;
}

.teacher .label{
  position: absolute;
  top:  20px;
  left: 10px;
  width: 100px;
  height:  20px;
  background-color: rgba(0, 0, 0, 0.479);
  border-radius: 5px;
  text-align: center;
  padding: 8px;
  font-size: 16px;
}

.teacher .teachrinfo{
  position: absolute;
  top: 350px;
  left: 20px;
  width: 265px;
  height:  30px;
  background-color: rgba(0, 0, 0, 0.479);
  border-radius: 5px;
  text-align: center;
  padding: 8px;
  font-size: 20px;
}


.classroom_header{
  position: relative;
  top:  0;
  left: 0;
  width: calc(100% - (10px));
  height:  140px;
  /* background-color: #00000052; */
  background-color: rgba(0, 0, 0, 0.479);
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  user-select: none;
}

.classroom_header .Slogo {
  position: relative;
  top:  30px;
  left: 80px;
  width: 80px;
  height: 80px;
}

.classroom_header .Slogo .classlogo{
  width: 70px;
  height: 70px;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

.classroom_header .aula{
  position: absolute;
  top:  30px;
  left: 200px;
  width: auto;
  height:  120px;
  border-radius: 5px;
  padding: 15px;
  margin: -15px;
  font-size: 16px;
}

.classroom_header .time{
  position: absolute;
  top:  30px;
  left: 280px;
  width: auto;
  height:  120px;
  border-radius: 5px;
  padding: 15px;
  margin: -15px;
  font-size: 16px;
}

.classroom_header .ids{
  position: absolute;
  top:  10px;
  left: 10px;
  width: auto;
  height:  120px;
  border-radius: 5px;
  padding: 15px;
  margin: -15px;
  font-size: 12px;
  opacity: 0.3;
}

.classroom_header .classroom{
  position: absolute;
  top:  50px;
  left: 200px;
  width: auto;
  height:  120px;
  border-radius: 5px;
  padding: 15px;
  margin: -15px;
  font-size: 32px;
}

.classroom_header .groupclass{
  position: absolute;
  top:  10px;
  left: 40px;
  width: auto;
  height:  120px;
  border-radius: 5px;
  padding: 15px;
  margin: -15px;
  font-size: 68px;
  font-weight: 600;
}

.classroom_header .nivel{
  position: absolute;
  top:  94px;
  left: 200px;
  width: auto;
  height:  120px;
  border-radius: 5px;
  padding: 15px;
  margin: -15px;
  font-size: 16px;
  font-weight: 100;
}

.classroom_header .students{
  position: absolute;
  top:  80px;
  right: 100px;
  width: auto;
  height:  120px;
  border-radius: 5px;
  padding: 15px;
  margin: -15px;
  font-size: 26px;
  font-weight: 100;

}

.classroom_header .number{
  position: absolute;
  top:  30px;
  right: 20px;
  width: auto;
  height:  120px;
  border-radius: 5px;
  padding: 15px;
  margin: -15px;
  font-size: 70px;
  font-weight: 600;
}

.project_header{
  position: relative;
  top:  0;
  left: 0;
  /* width: calc(100% - (10px)); */
  height:  240px;
  /* background-color: #00000052; */
  background-color: rgba(0, 0, 0, 0.479);
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  user-select: none;
}

.transbox {
  width: 100%;
  height:  100%;
  background-color: rgba(0, 0, 0, 0.479);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  border-radius: 10px;
  padding: 0px;
  margin: 0px;
  color: #FFF;
}

.projectlogo {
  position: relative;
  top:  50px;
  left: 80px;
  width: 100px;
  height: 100px;
  
}

.projectlogo .plogo{
  width: 100px;
  height: 100px;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}


.project_header .label{
  position: absolute;
  top:  55px;
  left: 200px;
  width: auto;
  height:  120px;
  border-radius: 5px;
  padding: 15px;
  margin: -15px;
  font-size: 16px;
}

.project_header .name{
  position: absolute;
  top:  80px;
  left: 200px;
  width: auto;
  height:  120px;
  border-radius: 5px;
  padding: 15px;
  margin: -15px;
  font-size: 24px;
  font-weight: 500;
}

.project_header .notes{
  position: absolute;
  top:  135px;
  left: 215px;
  width: 72%;
  height:  auto;
  border-radius: 5px;
  /* padding: 15px; */
  margin: -15px;
  font-size: 16px;
  font-weight: 300;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.project_header .users{
  position: absolute;
  top: 8px;
  left: 50px;
  /* width: 40%; */
  height:  0px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
}

.project_header .users:hover,
.project_header .icButtonSetings:hover,
.project_header .iuButtonUsers:hover {
  /* color: rgb(58, 138, 195); */
  opacity: 1;
}

.project_header .icButtonSetings {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 32px;
  height:  32px;
  cursor: pointer;
  padding: 8px;
  opacity: 0.5;
}


.project_header .iuButtonUsers {
  position: absolute;
  top: 8px;
  left: 60px;
  width: 80px;
  height:  32px;
  cursor: pointer;
  display: flex;
  opacity: 0.5;
}

.project_header .itButtonUsers {
  width: 32px;
  height:  32px;
  cursor: pointer;
  display: inline-block;
  align-items: center;
  text-align: center;
  padding: 8px;
}

.project_header .ittButtonUsers {
  font-weight: 300;
  font-size: 24px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: inline-block;
  align-items: left;
  text-align: left;
  padding: 3px;
  margin-left: -8px;
}


.project_header .company{
  position: absolute;
  bottom: 35px;
  left: 800px;
  /* width: 40%; */
  height:  0px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 300;
}

.project_header .type{
  position: absolute;
  bottom: 35px;
  left: 1000px;
  width: 40%;
  height:  0px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 300;
  
}

.project_header .loadlogo{
  position: absolute;
  top: 5px;
  right: 10px;
  width: auto;
  height:  0px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 300;
}

.project_header .loadimg{
  position: absolute;
  right: 70px;
  top: 5px;
  width: auto;
  height:  0px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 300;
}

.project_header .thema{
  position: absolute;
  right: 140px;
  top: 5px;
  width: auto;
  height:  0px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 300;
}

.cLabel {
  font-size: 16px;
  font-weight: 300;
  color: var(--text_color_menu);
}

.cLabelClassHeaderBar {
  font-size: 20px;
  font-weight: 600;
  color: var(--text_color_menu);
  margin-left: 60px;
  margin-top: -3px;
  width: 80%;
}


.bodylite{
  position: absolute;
  top:  var(--top_body);
  left: var(--menu_left_width);
  width: calc(100% - (var(--menu_left_width)));
  height:  calc(100% - var(--top_body));
  /* overflow-y: hidden; */
  overflow-x: hidden;
  background-color: var(--body_backcolor);
  transition: .3s; /*Скорость перехода состояния элемента*/
    animation-name: menuin;
    animation-duration: .3s;
}

.bodycenter {
  position: absolute;
  top:  var(--top_body);
  left: var(--menu_left_width);
  right: var(--menu_opt_width);
  width: calc(100% - (var(--menu_left_width) + var(--menu_opt_width) ));
  height:  calc(100% - var(--top_body));
  background-color: var(--body_backcolor);
  /* overflow-y: hidden; */
  overflow-x: hidden;
  transition: .3s; /*Скорость перехода состояния элемента*/
  animation-name: menuin;
  animation-duration: .3s;
}

.bodyleft {
  position: absolute;
  top:  var(--top_body);
  right: 0;
  width: 100%;
  height:  calc(100% - var(--top_body));
  background-color: var(--body_backcolor);
  /* overflow-y: hidden; */
  overflow-x: hidden;
  transition: .3s; /*Скорость перехода состояния элемента*/
  animation-name: menuin;
  animation-duration: .3s;
}

.bodyright{
  position: absolute;
  top:  var(--top_body);
  left: 0;
  right: 225px;
  width: calc(100% - (var(--menu_opt_width)));
  background-color: var(--body_backcolor);
  height:  calc(100% - var(--top_body));
  /* overflow-y: hidden; */
  overflow-x: hidden;
  transition: .3s; /*Скорость перехода состояния элемента*/
    animation-name: menuin;
    animation-duration: .3s;
}


.container{
  padding: 15px;
}

.container_page{
  padding: 15px;
  background-color: var(--card_backcolor);
  border-radius: 4px;
}



.menu_list_key{
  position: relative;
  display: block;
  top: 210px;
  left: 10px;
  width: 90%;
}

.list_key{
  position: relative;
  background-color: #FCB322;
  padding: 3px;
  margin: 3px;
  color: #2C3E50;
  width: 100%;
  display: block;
}

.list_key_active{
  position: relative;
  background-color: #FCB322;
  padding: 3px;
  margin: 3px;
  color: #3d1265;
  width: 95%;
  display: block;
}


.list_key:hover{
  background-color: #2C3E50;
  color: #FCB322;
}

.category_list_key{
  position: relative;
  display: block;
  top: 10px;
  left: 5px;
  width: 90%;
}

.category_list_key .list_key{
  background-color: #dedede;
  width: 95%;
}

.category_list_key .list_key:hover{
  background-color: #2C3E50;
  color: #FCB322;
}

.category_list_key a,.menu_list_key a {
  text-decoration: inherit; /* no underline */
  color: rgb(40, 40, 55);
}

details {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 0.5em 0.5em 0;
  margin: 3px;
  transition: all 0.3s;
}

summary {
  font-weight: bold;
  margin: -0.5em -0.5em 0;
  padding: 0.5em;
}

details[open] {
  padding: 0.5em;
  transition: all 0.3s;
}


details[open] summary {
  border-bottom: 1px solid #aaa;
  background-color: #bababa;

  margin-bottom: 0.5em;
  transition: all 0.3s;
}

.collapsible {
  /* background-color: #777; */
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}

details:hover .editgroup:hover {
  background: #2C3E50;
  color: #FCB322;
}

.list_key:hover .editlist:hover {
  background: #FCB322;
  color: #2C3E50;
}

details:hover .editgroup , .list_key:hover .editlist{
  visibility: visible;
}
details .editgroup {
  visibility: hidden;
  display: flex;
  position: absolute;
  cursor: pointer;
  color: #2C3E50;
  top: 6px;
  right: 6px;
  border-radius: 5px;
  background: #bababa;
  padding: 6px;
  font-size: 12px;
  transition: all 0.3s;
}

.list_key .editlist{
  visibility: hidden;
  display: flex;
  position: absolute;
  cursor: pointer;
  color:  #FCB322;
  top: 1px;
  right: 1px;
  border-radius: 1px;
  background:  #2C3E50;
  padding: 5px;
  font-size: 12px;
  transition: all 0.0s;
}

.list_key_active .editlist{
  visibility: visible;
  display: flex;
  position: absolute;
  cursor: pointer;
  color: #2C3E50;
  top: 1px;
  right: 1px;
  border-radius: 1px;
  background: #FCB322;
  padding: 5px;
  font-size: 12px;
  transition: all 0.0s;
}

.AdminBoxGroup {
  position: relative;
  padding:  3px 12px;
}

.addgroup:hover {
  background: #2C3E50;
  color: #FCB322;
}

 .addgroup {
  visibility: visible;
  display:inline-block;
  position: relative;
  cursor: pointer;
  color: #2C3E50;
  top: 6px;
  right: 6px;
  border-radius: 5px;
  background: #bababa;
  padding: 6px;
  font-size: 12px;
  transition: all 0.3s;
  margin: 2px;
}


/*Start Global Style*/

@import url("https://fonts.googleapis.com/css?family=Lato");




.wrapper {
  margin: 0 auto;
  padding: 32px 0 15px;
  width: 300px;
  height: calc(100% - 64px) ;
}

/************************
  HEADER
 */
 .header {
  text-align: center;
}
.header svg {
  fill: #2f363c;
}
.header h1 {
  color: #24292e;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -0.5px;
}

/************************
  FORM
 */
 .form {
  background: #FFF;
  border: 1px solid #DDD;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 15px;
}
.form label {
  color: #24292e;
  font-size: 12px;
  font-weight: 600;
}
.form label[for=password] span {
  color: #0366d6;
  float: right;
}
.form input {
  border-radius: 2px;
  margin: 10px 0;
  padding: 9px 7px;
  width: 100%;
}
.form .field {
  border: 1px solid #ddd;
  width: 95%;
}
.form .field:focus {
  border: 1px solid #0366D9;
  outline-color: rgba(37, 149, 255, 0.2);
  outline-style: solid;
  outline-width: 3px;
}
.form .button {
  background: #2DB84C;
  border: 1px solid #28a444;
  border-radius: 2px;
  color: #FFF;
  cursor: pointer;
  font-weight: 600;
}
.form .button:hover {
  background: #28a444;
}

/************************
  CREATE ACCOUNT
 */
.create-account {
  border: 1px solid #DDD;
  color: #0b0b0b;
  margin-top: 17px;
  text-align: center;
}

/************************
  FOOTER
 */
.footer {
  position: relative;
  font-size: 12px;
}
.footer ul {
  list-style-type: none;
}
.footer li {
  display: inline-block;
  margin: 0 5px;
}
.footer a {
  color: #323232;
  text-decoration: none;
}
.footer a:hover {
  color: #0366D6;
  text-decoration: underline;
}


.card-input {
  margin-bottom: 20px;
}
.card-input__label {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #1a3b5d;
  width: 100%;
  display: block;
  user-select: none;
}
.card-input__input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  font-family: "Source Sans Pro", sans-serif;
}
.card-input__input:hover, .card-input__input:focus {
  border-color: #3d9cff;
}
.card-input__input:focus {
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}


