
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
  /*background: var(--CalJL-text);*/
  cursor: pointer;
}

#slider3 {
  z-index: 0;
  padding: 25px;
}

#slider3 .rs-range-color  {
  background-color: #fcb322;
}

#slider3 .rs-path-color  {
  background-color: #000000;
  border-radius: 0;
}
#slider3 .rs-handle, #slider3 .rs-handle-dot {
  background-color: #fcb322;
  border: 0px solid #000000;

}

#slider3  .rs-handle-dot::after {
  background-color: #000000c2;
  border: 0px solid #AAAAAA;
}

#slider3 .rs-bg-color{
  background-color: #191919;
}

#slider3 .rs-tooltip-text{
  font-size: 38px;
  color: #fcb322;
}

#slider3 .rs-border  {
  border: 0px solid #fcb322;
}

/* taeb-switch styles */
.taeb-switch {
  position: relative;
}

.taeb-switch:after {
  content: "";
  position: absolute;
  width: 33%;
  top: 0;
  transition: left cubic-bezier(.88, -.35, .565, 1.35) .4s;
  border-radius: 10.5px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
  background-color: #fcb322;
  height: 100%;
  z-index: 0;
}

.taeb-switch.left:after {
  left: 0%;
}

.taeb-switch.center:after {
  left: 33.3%;
}

.taeb-switch.right:after {
  left: 66.6%;
}

.taeb-switch .taeb {
  display: inline-block;
  width: 33%;
  padding: 12px 0;
  z-index: 1;
  position: relative;
  cursor: pointer;
  transition: color 200ms;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  user-select: none;
  color: #fcb322;
}

.taeb-switch .taeb.active {
  color: #000000;
}

/* other styles */

.text-center {
  text-align: center;
}

h1 {
  font-size: 38px;
  font-weight: bold;
  display: block;
  width: 100%;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 40px;
}

main {
  width: 100%;
}

/* .wrapper {
  border-radius: 17px;
  background-color: #292929;
  padding: 8px;
  width: 100%;
  max-width: 316px;
  margin-left: auto;
  margin-right: auto;
} */


/* app styles */


.chartboxhistory {
  position: absolute;
  width: 450px;
  height: 200px;
  padding: 10px;
  left: 270px;
  z-index: 0;
}

.chartboxhistory2 {
  position: absolute;
  width: 230px;
  height: 200px;
  padding: 10px;
  left: 730px;
  z-index: 0;
}

.chartboxhistory3 {
  position: absolute;
  width: 230px;
  height: 200px;
  padding: 10px;
  left: 970px;
  z-index: 0;
}

.listboxhistory{
  position: absolute;
  width: 98%;
  height: 200px;
  padding: 10px;
  left: 0px;
  top: 0px;
  z-index: 0;
}

.HistotyCalender{
  position: relative;
  left: 20px;
  width: 50%;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: var(--list-norm);
  color:  var(--list-text);
  border: 0px solid #000000;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__header {
    text-align: center;
    background-color: var(--list-norm);
    border-bottom: 1px solid var(--borders-bg);
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: var(--list-text);
  /*active date*/
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: var(--btn-col);
  /*select date*/
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: var(--btn-sel);
  color: black !important;
  /*active date*/
}
.react-datepicker__day--today{
  color: black !important;
  background-color: var(--btn-col);
  border-radius: 0.3rem;
  /*today date*/
}

.react-datepicker__day-name, .react-datepicker__day {
  color: var(--fon-02);
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  margin-top: 0;
  color: var(--btn-col);
  font-weight: bold;
  font-size: 0.944rem;
}

.saback-box {
    position: absolute;
    width: 100%;
    height: calc(100% - 50px);
    top: 0px;
    left: 0px;
    overflow-x: auto;
    overflow-y: auto;
  }

  .incompany-box {
    position: relative;
    width: 97%;
    height: 120px;
    top: 0px;
    left: 0px;
    padding: 10px;
    margin: 10px;
    color: white;
  }

  .selcompany-box {
    position: relative;
    width: 80%;
    height: auto;
    top: 30px;
    left: 0px;
    padding: 10px;
    margin: 10px;
    color: white;
  }

  .txtempr{
    font-size: 24px;
    position: relative;
    width: auto;
    height: 35px;
    top: 0px;
    left: 0px;
    padding: 10px;
    margin: 10px;
    color: white;
  }
  
  .txtinfo{
    position: relative;
    display: inline-block;
    width: 95%;
    height: auto;
    top: 0px;
    left: 3px;
    padding-top: 5px;
    /*margin: 10px;*/
    color: rgb(128, 128, 128);
  }

  .tablinfo{
    position: relative;
    width: 95%;
    height: auto;
    top: 3px;
    left: 10px;
    text-align: right;
    color: rgb(128, 128, 128);
  }

  .comp_pin {
    font-size: 18px;
    background-color: var(--hover-item);
    color: var(--btn-col);
    border: 0px;
    width: 100%;
    height: 35px;
    left: 0px;
    top: 10px;
    position: relative;
  }

  .page_hide {
    display: none;
  }

  .page_show {
    display: block;
  }

  .txticonc {
    z-index: 1;
    top: 5px;
    left: 15px;
    width: 256px;
    height: 256px;
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    border-radius: 10%;
    background-size: cover;
}

.list_comp_numer {
  z-index: 1;
  text-align: center;
  position: absolute;
  width: 40px;
  font-size: 24px;
  color: var(--list-text, #e4e4e4);
    top: 5px;
    left: 10px;
}

.list_comp_hez {
  position: absolute;
  opacity: 1;
  z-index: 10;
  color: var(--list-text, #e4e4e4);
  display: inline-block;
  text-align: left;
  font-size: 12px;
  left: 59%;
  top: 10px;
  width: 40%;
  margin-top: 1px;

  /*-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0,0px,0);*/
  transform: translate3d(0px,0px,0);
}

.list_comp_pimg {
  z-index: 1;
  top: 10px;
  left: 55px;
  width: 30px;
  height: 30px;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  background-size: cover;
}

.list_comp_het {
  position: absolute;
  opacity: 1;
  z-index: 10;
  color: var(--list-text, #e4e4e4);
  text-shadow: 0px 0px #646464;
  display: inline-block;
  text-align: left;
  font-size: 18px;
  left: 120px;
  top: 10px;
  width: 94%;
  margin-top: 0px;
}

.comp_content {
  top: 270px;
  left: 0px;
  width: 90%;
  height: auto;
  position: absolute;
  display: inline-block;
}

.savemodal{
  bottom: 80px;
  width: 95%;
  height: auto;
  position: fixed;
  left: 5%;
}

  .findsuser {
    font-size: 15px;
    background-color: rgba(20, 20, 20, 0.568);
    color: var(--btn-col);
    border: 0px;
    width: auto;
    height: 40px;
    left: 0px;
    top: 0px;
    position: relative;
    margin: 3px;
    border-left: 2px solid var(--btn-col, #6698c8);
  }

  .info .infotext {
    text-align: left;
    font-size: 18px;
    margin: 0;
    padding: 0;
    color: var(--fon-02,#FCB322);
    display: inline-block;
  }

  .set_key:hover {
    color: var(--btn-col,#FCB322);
  }

  .set_key {
    height: 20px;
    width: 20px;
    font-size: 16px;
    color: white;
    margin: 5px;
    text-align: center;
  }


  .frmtextbox {
    font-size: 15px;
    background-color: var(--CalJL-calender-day);
    color: var(--btn-col);
    border: 0px;
    width: 65%;
    height: 35px;
    margin: 3px;
  }

  .frmtextboxfull {
    font-size: 15px;
    background-color: var(--CalJL-calender-day);
    color: var(--btn-col);
    border: 0px;
    width: 95%;
    left: 0px;
    height: auto;
    margin: 3px;
    resize: none;
  }

  .new-line {
    white-space: pre-line;
  }

  .bolok
  {
  position: relative;  
  top: 40px;
  }

  .lbllist
{
  display: block;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    width: 65%;
}
.react-datepicker__input-container, .react-datepicker-wrapper .frmtextbox{
  width: 100%;
}


 .textlabel
{
    pointer-events: none;
    display: inline-block;
    text-align: right;
    width: 30%;
    padding: 0px;
    margin: 0px;
    font-size: 16px;
    color: var(--btn-col);
}

.cont_ava{
  /*overflow-y: scroll;*/
  width: 40%;
  height: auto;
  position: relative;
  top: 10px;
  left: 10px;
}

.cont_tabs{
  position: absolute;
  width: 320px;
  margin: 10px;
}

.frmlistbox {
    font-size: 15px;
    background-color: var(--CalJL-calender-day);
    color: var(--btn-col);
    border: 0px;
    width: 65%;
    height: 35px;
    margin: 3px;
    border-radius: 3px; 
  }

  .frmlistbox option {
    color:  #fcb322;
    background-color:  #000000c2;
    line-height: 2.0em;
    font-size: 1rem;
  }
  
  .frmlistbox option:checked {
    /* color:  var(--CalJL-listback); */
    color:  #000000c2;
    background-color: var(--btn-col);
  }

  .conteiners {
    /*display: flex;*/
    padding: 0 10px;
    flex-wrap:wrap;
    overflow-y: scroll;
    overflow: hidden;
    /*overflow-y: scroll;*/
    width: calc( 100% - 380px);
    height: calc( 100% - 50px);
    position: absolute;
    top: 45px;
    left: 350px;
  }

  .conteiner1 {
    overflow: hidden;
    /*overflow-y: scroll;*/
    width: calc(100% - 10px);
    height: auto;
    position: relative;
    top: 5px;
    left: 20px;
  }

  .conteiner2 {
    width: calc(100% - 10px);
    height: auto;
    position: relative;
    top: 20px;
    left: 20px;
  }

  

  .btntools{
    position: relative;
    top: 0px;
    left: 5px;
    padding-top: 10px;
    width: 98%;
  }

  .blite{
    height: 32px;
    font-size: 12px;
    padding: 0px;
    margin: 3px;
  }

  .sulite{
    top: 0px;
    width: 40px;
    height: 40px;
    font-size: 15px;
    padding: 0px;
    margin: 3px;
  }

.listbody {
    display: flex;
    padding: 0 10px;
    width: auto;
    flex-wrap:wrap;
  }

.listbody > div{
    position: relative;
    background: rgb(74,86,100);
    background: linear-gradient(60deg, rgba(74,86,100,1) 0%, rgba(44,56,73,1) 63%, rgba(48,62,77,1) 100%);
    padding:10px;
    flex: 1 1 auto; /* dynamic width */
    border:0px solid var(--btn-col);
    min-width: 230px;
    max-width: 320px;
    width: calc( 20% - 30px);
    height: 70px;
    margin: 5px;
    border-radius: 5px;
  }

  .listbody > div .saava {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    z-index: 0;
}



.ava_user_center {
  position: relative;
  left: calc(50% - 40px);
  width: 80px;
  height: 80px;
  border-radius: 50px;
}

.suava {
  position: relative;
  left: 40px;
  top: 10px;
  width: 160px;
  height: 160px;
  border-radius: 5px;
  padding: 15px;
}

.listbody > div .textid {
    position: absolute;
    font-size: 12px;
    top: 5px;
    right: 5px;
    color:dimgray;
    margin: 0px;
}

.listbody > div .said {
    position: absolute;
    font-size: 16px;
    bottom: 5px;
    left: 5px;
    color:var(--li-min-color);
    margin: 0px;
}

.listbody > div .textnm {
    position: absolute;
    font-size: 20px;
    text-align: center;
    margin: 5px;
    top: 0px;
    left: 80px;
    color:  var(--fon-01);
}

.listbody > div .textcom {
  position: relative;
  font-size: 18px;
  text-align: left;
  text-transform: lowercase;
  margin: 0px;
  padding: 0px;
  width: 65%;
  top: -6px;
  left: 80px;
  color:  var(--fon-01);
}

.listbody > div .textcinfo {
  position: relative;
  font-size: 12px;
  text-align: left;
  width: 70%;
  color:  var(--btn-col);
  top: -5px;
  left: 80px;
  margin: 0px;
}

.listbody > div .textml {
    position: absolute;
    font-size: 12px;
    text-align: center;
    top: 20px;
    left: 90px;
    color:  var(--btn-col);
}

.listbody > div:hover {
    background: var(--hover-item, #ffc400);
    border-left: 5px solid var(--btn-col, #6698c8);
    width: calc( 20% - 35px);
    min-width: calc( 230px - 5px);
}

.listbody > div .butbox {
    position: absolute;
    display: block;
    text-align: right;
    float: left;
    height: 50px;
    width: 150px;
    bottom: 0px;
    right: 0px;
    padding: 0px;
    margin: 0px;
}

.listbody > div .downicol {
    opacity: 0;
    margin: 5px;
    display: inline-block;
    position: relative;
    /*border: 3px solid #000000;*/

}

.listbody > div:hover .downicol {
    opacity: 1;
}

.listbody > div:hover .key_setings {
    height: 32px;
    width: 32px;
    top: 0px;
    right: 0px;
    font-size: 32px;
    color: white;
    position: relative;
}

.listbody > div:hover .key_setings:hover {
    color: rgb(255, 174, 0);
}

.downmodal {
  display: block;
}



