.grid_invite_user {
    position: relative;
    list-style-type: none;
    margin: 0px;
    padding: 5px;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
}

.lisinmarker {
    display: list-item;
    text-align: -webkit-match-parent;
    margin: 3px;
}

.lisinmarker.active > .listcards .linenumero,
.lisinmarker.active > .listcards .reference,
.lisinmarker.active > .listcards .datatime {
    color: var(--menu_backcolor);
    background-color: green;
}

.lisinmarker.active > .listcards .type  {
    border: 1px solid green;
}


.userlist{
    overflow: hidden;
    position: relative;
    width: 99%;
    margin: 0px;
    padding: 0px;
    background-color: hsla(0, 0%, 95%, 0.918);
    height: 45px;
    z-index: 0;
    border-radius: 5px;
}

.userlist:hover{
    background-color: hsla(0, 0%, 85%, 0.568);
}

.userlist .linenumero{
    position: absolute;
    text-align: center;
    z-index: 1;
    font-size: 14px;
    left: 3px;
    top: 3px;
    width: 20px;
    margin: 10px;
}

.userlist .outdate{
    position: absolute;
    text-align: center;
    z-index: 1;
    font-size: 11px;
    left: 330px;
    top: 3px;
    margin: 3px;
    color: grey
}

.userlist .acdate{
    position: absolute;
    text-align: center;
    z-index: 1;
    font-size: 11px;
    left: 330px;
    top: 20px;
    margin: 3px;
    color: grey
}

.userlist .email{
    position: absolute;
    text-align: center;
    z-index: 1;
    font-size: 16px;
    left: 33px;
    top: 0px;
    width: 300px;
    margin: 10px;
    text-align: left;
}

.userlist .status {
    position: absolute;
    text-align: center;
    z-index: 1;
    font-size: 16px;
    right: 0px;
    top: 0px;
    width: 30px;
    margin: 10px;
}

.grid_project_users {
    position: relative;
    list-style-type: none;
    margin: 0px;
    padding: 5px;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
}

.grid_project_users .lisinmarker {
    display: list-item;
    text-align: -webkit-match-parent;
    margin: 3px;
}

.grid_project_users .userslist{
    overflow: hidden;
    position: relative;
    width: 95%;
    margin: 3px;
    padding: 0px;
    background-color: hsla(0, 0%, 95%, 0.918);
    height: 50px;
    z-index: 0;
    border-radius: 5px;
}

.grid_project_users .userslist:hover{
    background-color: hsla(0, 0%, 85%, 0.568);
}

.grid_project_users .userslist .sLogo {
    position: absolute;
    left: 30px;
    top: 0px;
    width: 40px;
    margin: 5px;
    border-radius: 5px;
}

.grid_project_users .userslist .name {
    position: absolute;
    text-align: left;
    z-index: 1;
    font-size: 16px;
    font-weight: 600;
    left: 70px;
    top: 0px;
    width: 300px;
    margin: 5px;
}

.grid_project_users .userslist .email {
    position: absolute;
    text-align: left;
    z-index: 1;
    font-size: 12px;
    left: 75px;
    top: 20px;
    width: 300px;
    margin: 5px;
}

.grid_project_users .userslist .linenumero {
    position: absolute;
    text-align: center;
    z-index: 1;
    font-size: 12px;
    left: 0px;
    top: 0px;
    width: 25px;
    margin: 5px;
    z-index: 10;
}

.users_students {
    border-left: 6px solid orange;
}

.users_teacher {
    border-left: 6px solid green;
}

.users_users {
    border-left: 6px solid grey;
}

.user_filter_role {
    display: inline;
}


.frole_students{
    display:  inline-block;
    background-color: orange;
    font-size: 12px;
    padding: 5px;
    margin: 3px;
    font-weight: 600;
    border-radius: 15% / 50%;
    width: 75px;
    text-align: center;
}

.frole_teacher{
    display:  inline-block;
    background-color: green;
    font-size: 12px;
    padding: 5px;
    margin: 3px;
    font-weight: 600;
    border-radius: 15% / 50%;
    width: 75px;
    text-align: center;
}

.frole_otros{
    display:  inline-block;
    background-color: grey;
    font-size: 12px;
    padding: 5px;
    margin: 3px;
    font-weight: 600;
    border-radius: 15% / 50%;
    width: 75px;
    text-align: center;
}

.card3 .actions {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 105px;
    height: 52px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .card3:hover .actions {
    opacity: 1;
  }

  .card3 .actions .edit-btn{
    display:inline-block;
    margin: 5px;
    border: none;
    /* border-radius: 3px; */
    width: 42px;
    height: 42px;
    border-radius: 50%;
    opacity: 0.5;
    cursor: pointer;
  }

  .card3 .actions .edit-btn:hover{
    background-color: rgba(0, 0, 0, 0.355);
    opacity: 1;
  }