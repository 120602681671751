.StyleLinkersBar {
    position: absolute;
    width: 100%;
    top: 50px;
    left: 0px;
    font-size: 12px;
    /* left: var(--menu_left_width);
    width: calc(100% - (var(--menu_left_width))); */
    height: 25px;
    padding: 0px;
    margin: 0px;
    background-color: #d3d3d3;
}

.StyleLinkersBar a{
    color: #8b9194;
}

.breadcrumb {
    /* padding: 1rem 1rem; */
    margin: 4px;
    margin-left: -5%;
    list-style: none;
    display: flex; 
    justify-content: center;
    align-items: center;

    /* background-color: #eceeef; */
  }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  padding-left: .5rem;
  color: #636c72;
  content: "/";
}

  .breadcrumb-item {
    float: left;
  }

  .breadcrumb-item.active {
    color: #e1e7ec;
  }