
.ank_main {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: calc(100%);
    overflow-x: hidden;
    overflow-y:scroll;
    /* background-color: #00000096; */
}

.ank_main_run {
  position: fixed;
  left: 0px;
  top: 61px;
  width: 100%;
  height: calc(100% - 141px);
  overflow-x: hidden;
  overflow-y:scroll;
  /* background-color: #00000096; */
}

.icon_back{
  position: relative;
  left: 0;
  top: 0;
  width: 34px;
  color: aliceblue;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  padding: 8px 15px 15px;
}

.icon_back_main{
    position: relative;
    /* background-color:rgb(0 0 0 / 50%); */
    border-radius: 50%;
    left: 0;
    top: 0;
    width: 57px;
    height: 57px;
    margin: 16px;
}

.studentinfo {
  position: absolute;
  top: 15px;
  right: 25px;
  width: 80%;
  text-align: right;

}
.ank_main .aHello {
  position: relative;
  font-weight: 300;
  font-size: 42px;
  color: var(--menu_backcolor);
  user-select: none;
}

.ank_main .BoxRang {
  position: absolute;
  display: flex;
  top: 100px;
  left: 10px;
  width: 90%;
  color: var(--card_text_color);
}


.ank_main .tHello {
    position: absolute;
    font-weight: 300;
    font-size: 22px;
    color: var(--menu_backcolor);
    user-select: none;
    left: 70px;
    top: 15px;
    width: CALC(100% - 160px);
    text-align: center;
}

.ank_main .aName {
    position: relative;
    font-weight: 400;
    font-size: 36px;
    color: var(--menu_backcolor);
    top: -15px;
}

.ank_main .aNick {
    position: relative;
    font-weight: 300;
    font-size: 20px;
    color: var(--card_text_color);
    top: -20px;
}

.ank_main .aClassList{
    position: absolute;
    top: 250px;
    right: 15px;
    width: 90%;
    height: 10%;
    border: 0px solid red;
}

.ank_main .aClassList .ankLogo{
    position: absolute;
    top: 25px;
    color: aliceblue;
    background-color: #ff7a338a;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    padding: 10px;
}

.ank_main .aClassList .ankName{
    position: absolute;
    top: 10px;
    left: 100px;
    font-weight: 400;
    font-size: 24px;
    color: var(--menu_backcolor);
}

.ank_main .aClassList .ankPowerBy{
    position: absolute;
    top: 45px;
    left: 100px;
    font-weight: 300;
    font-size: 18px;
    color: var(--card_text_color);
}

.ank_main .aClassList .ankThema{
  position: absolute;
  top: 70px;
  left: 100px;
  font-weight: 300;
  font-size: 18px;
  color: var(--menu_backcolor);
}


.ank_main .aClassList .ankSellvl{
    position: absolute;
    top: 105px;
    left: 100px;
    font-weight: 600;
    font-size: 14px;
    width: 100px;
    color: var(--text_color_menu);
    background-color: chartreuse;
    border-radius: 30px;
    text-align: center;
}

.ank_main .aClassList .ankProgres{
    position: absolute;
    top: 105px;
    left: 220px;
    font-weight: 600;
    font-size: 14px;
    width: 100px;
    color:  rgb(255, 255, 255);
    background-color:dodgerblue;
    border-radius: 30px;
    text-align: center;
}

.ThemaName {
  font-size: 18px;
  letter-spacing: .4rem;
  color: #ffffff;
  padding: 8px;
}

.ank_main .aClassList .BoxRang {
    position: absolute;
    display: flex;
    top: 100px;
    left: 10px;
    width: 90%;
    color: var(--card_text_color);
}

.BoxRangNext {
  position: relative;
  display: flex;
  width: 100%;
  left: 0px;
}

.BoxRangBody {
  position: relative;
  display: flex;
  width: 100%;
  left: 0px;
  border-radius: 5px;
  background-color:rgb(0 0 0 / 50%);
  color: var(--menu_backcolor);
  padding: 10px;
  width: CALC(100% - 28px);
  min-height: 120px;
}


.ank_main .aClassList .Box1 {
    position: relative;
    width: 100%;
    font-size: 55px;
    color: var(--menu_backcolor);
    background-color:rgb(0 0 0 / 50%);
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    margin-right: 8px;
    min-width: 80px;
}

.Box1lbl {
    font-size: 15px;
}

.BoxRun{
  position: relative;
  display: flex;
  width: 100%;
  left: 0px;
  top: 15px;
}

.ank_main .aClassList .ankRunChoice{
  position: relative;
  letter-spacing: .3rem;
  width: 40px;
  height: 40px;
  top: 0px;
  right: 0px;
  font-size: 16px;
  color:rgb(255, 255, 255);
  background-color:rgba(255, 127, 80, 0.603);
  border-radius: 50%;
  text-align: center;
  margin: 8px;
  padding: 8px;
}


.ank_main .aClassList .ankRun{
    position: relative;
    font-weight: 600;
    font-size: 20px;
    width: 70%;
    color:rgb(255, 255, 255);
    background-color:rgb(255, 127, 80);
    border-radius: 30px;
    text-align: center;
    margin: 8px;
    padding: 10px;
}

.ank_main_run .ankPlay{
  position: absolute;
  top: 100px;
  right: 15px;
  font-weight: 600;
  font-size: 20px;
  width: 30px;
  color:rgb(255, 255, 255);
  background-color:rgba(255, 127, 80,0.3);
  border-radius: 30px;
  text-align: center;
  margin: 8px;
  padding: 8px;
}

.ank_main_run .aClassRun{
    position: absolute;
    display: grid;
    grid-template-columns: 1fr; /* Opcional: especifica el ancho de la columna */
    grid-auto-rows: auto; /* Opcional: especifica el alto de las filas */
    top: 0px;
    right: 15px;
    width: 90%;
}

.refID {
    position: absolute;
    top: 10px;
    left: 80px;
    font-weight: 400;
    font-size: 18px;
    color:mediumslateblue;
}

.aClassRun .Side_A {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .aClassRun .wmde-markdown {
    font-size: 20px;
    color: #ffffff;
    background-color:  #8f8f8f00;
  }

  .aClassRun .Side_A img {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 10px;
  }

  .aClassRun .Side_B {
    display: flex;
    flex-direction: column;
    top: 20px;
    left: 0px;
    width: 100%;
    height: auto;
  }

  .ankShowB{
    display: flex;
    flex-direction:row;
    font-weight: 600;
    font-size: 20px;
    width: 90%;
    color:white;
    background-color:coral;
    border-radius: 30px;
    text-align: center;
    margin: 6px;
    padding: 8px;
}

.ankShowB i{ 
  text-align: center;
  margin: 6px 28px;
}


.ank_main .aClassRun .BtnPlay {
    position: absolute;
    bottom: 60px;
    left: CALC(50% - 120px);
    font-weight: 600;
    font-size: 20px;
    width: 200px;
    color: var(--text_color_menu);
    background-color:coral;
    border-radius: 30px;
    text-align: center;
    margin: 8px;
    padding: 8px;
  }

  .refB {
    position: absolute;
    top: 10px;
    right: 125px;
    font-weight: 400;
    font-size: 18px;
    color:dodgerblue;
  }

  .refR {
    position: absolute;
    top: 10px;
    right: 65px;
    font-weight: 400;
    font-size: 18px;
    color:orangered;
  }

  .refG {
    position: absolute;
    top: 10px;
    right: 15px;
    font-weight: 400;
    font-size: 18px;
    color:greenyellow;
  }

   .btnRespuest {
    position: fixed;
    display: flex;
    bottom: 15px;
    left: 10px;
    width: calc(100% - 20px);
    color:greenyellow;
    user-select: none;
  }

  .appinfopanel {
    position: fixed;
    top: 0px;
    left: 0px;
    width: calc(100%);
    user-select: none;
    background-color: #00000096;
    z-index: 3;
  }

   .btnRespuest .btnR1{
    position: relative;
    width: 20%;
    font-size: 18px;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    margin: 5px;
  }

  .cb_1{
    color: var(--menu_backcolor);
    background-color: orangered;
  }

  .cb_2{
    color: var(--menu_backcolor);
    background-color:var(--text_color_menu);
  }

  .cb_3{
    color: var(--menu_backcolor);
    background-color: green;
  }

  .cb_4{
    color: var(--menu_backcolor);
    background-color: blue;
  }

  .ank_main .btnRespuest  .btnR1lbl {
    font-size: 15px;
}
