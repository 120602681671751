
#APPmyProgress {
    width: 92%;
     background-color: #d9d9f233; 
    cursor: pointer;
    border-radius: 10px;
    margin-left: 13px;
  }
  
  #APPmyBar {
    width: 0%;
    height: 5px;
    background-color: #ffc266;
    border-radius: 10px;
  }
  
  .app_btn-action{
    cursor: pointer;
    padding-top: 10px;
    width: 30px;
  }
  
  .app_btn-ctn, .app_infos-ctn{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .app_infos-ctn{
  padding-top: 20px;
  }
  
  .app_btn-ctn > div {
   padding: 10px;
   margin-top: 18px;
   margin-bottom: 18px;
   position: relative;
   
  }
  
  .app_infos-ctn > div {
   margin-bottom: 8px;
   color: #ffc266;
  }
  
  .first-btn{
    margin-left: 3px;
  }
  
  .app_duration{
    margin-left: 10px;
  }
  
  .app_title{
    margin-left: 10px;
    width: 210px;
    text-align: center;
  }
  
  .app_player-ctn{
    border-radius: 15px;
    width: 100%;
    padding: 3px;
    margin:auto;
    position: fixed;
    bottom: 70px;
    z-index: 3;
    /* background-color: #4646460e; */
  }

  .player_ank_main {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 73%;
    overflow-x: hidden;

    background-color: #1E1E1E;
}

  
  
  .app_playlist-track-ctn{
    display: flex;
    background-color: #4646463d;
    margin-top: 3px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 8px;
    margin-right: 8px;
  }
  
  .app_playlist-track-ctn > div{
    margin:1px 7px;
  }
  .playlist-info-track{
    width: 80%;
  }
  .playlist-info-track,.playlist-duration{
    padding-top: 7px;
    padding-bottom: 7px;
    color: #e9cc95;
    font-size: 14px;
    pointer-events: none;
  }
  .app_playlist{
    position: absolute;
    height: 100px;
    width: auto;
    z-index: 0;
  }
  .active-track{
    background: #4d4d4d;
    color: #ffc266 !important;
    font-weight: bold;
    
  }
  
  .active-track > .playlist-info-track,.active-track >.playlist-duration,.active-track > .playlist-btn-play{
    color: #ffc266 !important;
  }
  
  
  .playlist-btn-play{
    pointer-events: none;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .fas{
    color: #ffc266;
    font-size: 20px;
  }


  /* Slider */
  

  @media (max-width: 800px) {
    .playerui {
      position: fixed;
    }
  }