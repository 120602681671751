:root {
  --foreground-color: white;
  --size: 6rem;
  --transition-duration: 500ms;
  --transition-easing: ease-out;
}

.user-select {
    position: relative;
    /* display: inline-block; */
    width: 200px; /* ajusta el ancho según sea necesario */
    /* top: 5px; */
  }
  
  .selected-user {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .selected-user img {
    width: 30px; /* ajusta el tamaño de la imagen según sea necesario */
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px; /* ajusta la altura máxima según sea necesario */
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    z-index: 3;
  }
  
  .options div {
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .options div:hover {
    background-color: #f0f0f0;
  }
  
  .options div img {
    width: 20px; /* ajusta el tamaño de la imagen según sea necesario */
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
  }
  

  /* list view */

  .list-view {
    display: flex;
  }
  
  .user-item {
    margin: 8px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    transition: opacity 0.3s ease;
  }
  
  .user-item img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .user-item span {
    margin-right: 8px;
  }
  
  .visible {
    opacity: 1;
  }
  
  .hidden {
    opacity: 0;
    pointer-events: none; /* Evita interacciones con elementos ocultos */
  }
  
  /* listbox */

  .list-box {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 360px;
    user-select: none;
    /* margin: 10px; */
  }

  .list-group {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    margin-left: 10px;
  }

  .list-group-header {
    position: absolute;
    right: 10px;  
}

  .list-group-body {
    display: flex;
  }
  
  .list-box h2 {
    margin-top: 0;
  }
  
  .list-box ul {
    list-style-type: none;
    padding: 0;
  }
  
  .list-box li {
    cursor: pointer;
    padding: 5px;
    border-radius: 4px;
    margin-bottom: 5px;
    background-color: #f0f0f0;
  }
  
  .list-box li:hover {
    background-color: #e0e0e0;
  }
  
  
  .list-boxes {
    display: flex;
  }

  .inline {
    display: flex;
    flex-direction: row;
  }

  .input_field button {
    width: 100%;
    height: 35px;
    width: 30px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    outline: none;
    background: none;
    color: #333;
    text-align: center;
  }

  .input_field button:hover {
    background: #f5ba1a;;
  }

  .form_2 {
    padding: 25px;
  }

  .form_2 input[type="date"] {
    width: 100%;
    padding: 8px 10px 9px 35px;
    height: 35px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    outline: none;
  }

  /* TABLA STATISTIC */

  .lisistatic {
    display: list-item;
    text-align: -webkit-match-parent;
    margin: 0px;
    
  }

  .lisistatic .linenumero {
    display:inline-block;
    color:#333;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    padding: 3px;
    width: 50px;
    text-align: center;
  }

  .lisistatic .type {
    display:inline-block;
    color:#333;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    padding: 3px;
    margin: 1px;
    width: 60px;
    text-align: center;
  }

  .lisistatic .reference {
    display:inline-block;
    color:#333;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    padding: 3px;
    margin: 1px;
    width: 100px;
    text-align: center;
  }


  .lisistatic .datatime {
    display:inline-block;
    color:#333;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    padding: 3px;
    margin: 1px;
    width: 200px;
    text-align: center;
  }

  .lisistatic .cb_0{
    color: var(--menu_backcolor);
    background-color: green;
  }

  .lisistatic .cb_1{
    color: orangered;
  }

  .lisistatic .cb_2{
    color:var(--text_color_menu);
  }

  .lisistatic .cb_3{
    color: green;
  }

  .lisistatic .cb_4{
    color: blue;
  }

.list-box .title {
  color: #000;
  font-weight: 600;
  text-align: left;
  font-size: 24px;
  margin-left: 10px;
}

.list-box .label  {
  color: #000;
  font-weight: 400;
  text-align: left;
  font-size: 14px;
  margin-left: 10px;
}

.list-box .cart_body {
  position: relative;
  border-radius: 5px;
  margin: 3px;
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
}

.list-box .cart_body:hover {
  background-color: #ffe5b0;
}

.list-group .header_body {
  position: relative;  
  height: 100px;
}

.list-box .cart_body .icon, .list-group .header_body .icon {
  width: 56px;
}

.list-box .cart_body .name, .list-group .header_body .name{
  position: absolute;
  top: 0px;
  left: 60px;
  color: #000;
  font-weight: 600;
  text-align: left;
  font-size: 18px;
}

.list-box .cart_body .label{
  position: absolute;
  top: 25px;
  left: 50px;
  color: #000;
  font-weight: 400;
  text-align: left;
  font-size: 10px;
  width: 60%;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}

.list-group .header_body .label{
  position: absolute;
  top: 25px;
  left: 65px;
  color: #000;
  font-weight: 400;
  text-align: left;
  font-size: 18px;
}

.list-box .cart_body .group{
  position: absolute;
  top: 5px;
  right: 5px;
  color: #000;
  font-weight: 600;
  text-align: left;
  font-size: 32px;
}

.list-group .header_body .descriptions{
  position: absolute;
  top: 60px;
  left: 5px;
  color: #000;
  font-weight: 400;
  text-align: left;
  font-size: 16px;
}


.list-box .actions cart_body:hover {
  background-color: #bbb;
}

.cart_body .actions {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 105px;
  height: 52px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.cart_body:hover .actions {
  opacity: 1;
}

.cart_body .actions .edit-btn{
  display:inline-block;
  margin: 5px;
  border: none;
  /* border-radius: 3px; */
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.list-group-header .edit-btn {
  display: inline-block;
  width: 45px;
  height: 45px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  margin: 3px;
  position: relative;
  top: 5px;
}


.animate_body {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  /* height: 100vh; */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}