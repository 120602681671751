:root {
  --column-bg: #303e4d; /* barmenu */ 
  --borders-bg: #424952; /* barmenu */ 
  --menu-bg-hover: #2c3849; /* menu */ 
  --active-item: #6698c8; /* menu */ 
  --active-item-text: #ffffff; /* menu */ 
  --hover-item: #4a5664; /* active list */ 
  --text-color: #ffffff; /* text-color */ 
  --fon-01: #ffffff; /* fondo 01 */ 
  --fon-02: #c7c7c7; /* fondo 01 */ 
  --li-text-color: #ffffff; /* lista-color */ 
  --li-mas-color: #22fc51; /* lista-color */ 
  --li-min-color: #fc6722; /* lista-color */ 
  --coment-text-color: #c7c7c7; /* comentario-color */ 
  --active-presence: #94e864; /* punto lista */ 
  --mention-badge: #78af8f; /* menu */ 
  --btn-col: #fcb322; /* buttons */ 
  --btn-sel: #fcd079; /* buttons */ 
  --btn-sdw: #b37f18; /* buttons */ 
  --menu-max: 325px; /* menu */ 
  --fonts-zoom: 50%; /* menu */ 
  --menu-page: "user"; /* menu */ 
  --trans-opacity: 0.98; /* opacity fff000 */ 

  --text-placeholder: #303e4d; /* barmenu */ 

  --trans-gcolorn: 0.65; /* opacity contact group norm*/ 
  --trans-gcolorh: 0.90; /* opacity contact group hover */

  --cgroup-color-001: #ff0000; /* buttons */ 
  --cgroup-color-002: #ff5100; /* buttons */ 
  --cgroup-color-003: #ffa600; /* buttons */ 
  --cgroup-color-004: #e5ff00; /* buttons */ 
  --cgroup-color-005: #48ff00; /* buttons */ 
  --cgroup-color-006: #00ff4c; /* buttons */ 
  --cgroup-color-007: #00d9ff; /* buttons */ 
  --cgroup-color-008: #008cff; /* buttons */ 
  --cgroup-color-009: #003cff; /* buttons */ 
  --cgroup-color-010: #7700ff; /* buttons */ 
  --cgroup-color-011: #f700ff; /* buttons */ 
  --cgroup-color-012: #cf2020; /* buttons */ 
  --cgroup-color-013: #cf9220; /* buttons */ 
  --cgroup-color-014: #6fcf20; /* buttons */
  --cgroup-color-015: #ffffff; /* buttons */
  --cgroup-color-016: #c7c7c7; /* buttons */
  --cgroup-color-017: #303e4d; /* buttons */
  --cgroup-color-018: #fcb322; /* buttons */
  --cgroup-color-019: #464646; /* buttons */
  --cgroup-color-020: #141414; /* buttons */
  --cgroup-color-021: #0e5e0e; /* buttons */ 
  --listuserold-max: 105;

}

/* body {
  background-color: #2b2a2b;
}  */

#page-preloader {position: fixed;left: 0;top: 0;right:0;bottom:0;z-index: 1040;}

.ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  /* border:3px solid #3c3c3c; */
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:var(--btn-col,#2C3E50);;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px var(--btn-col,#2C3E50);;
  box-shadow:0 0 20px rgba(0,0,0,.5);
}
.ring:before
{
  content:'';
  position:absolute;
  top:-2px;
  left:-2px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid var(--btn-col,#2C3E50);;
  border-right:3px solid var(--btn-col,#2C3E50);;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
.ld
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.ld:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background: var(--btn-col,#2C3E50);
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px var(--btn-col,#2C3E50);
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}