:root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  line-height: 1.6;
  --color-white: #ffffff;
  --color-background-info: #0070D2;
  --color-content-light: white;
  --color-hover-tint: rgba(0,0,0,0.1);
  --color-warning: #C23934;
  --color-success: #4bb148;
  --color-pending: #ff9113;
  --color-norm: #acacac;
}





.empty {
  visibility: hidden;
}

h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}



.PreviewPageInvite{
  padding: 50px;
  margin: 50px;
}
.form_0 input[type='date']   {
  width: 100%;
  padding: 8px 10px 9px 35px;
  height: 35px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  outline: none;
}
.form_0 input[type='date']:focus   {
  box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  border: 1px solid #f5ba1a;
  background: #fafafa;
}

.form_0 input[type='datetime-local']   {
  width: 100%;
  padding: 8px 10px 9px 35px;
  height: 35px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  outline: none;
}
.form_0 input[type='datetime-local']:focus   {
  box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  border: 1px solid #f5ba1a;
  background: #fafafa;
}


.form_0 fieldset {
  border: 1px solid #cccccc;
  border-radius: 3px;
}

/* SLIDE CHECK */

.label {
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
}
.toggle {
  display: none;
  padding: 6px;
}
.toggle:checked ~ .toggle-check {
  background: #2dcb6d;
  border-color: #2dcb6d;
}
.toggle:checked ~ .toggle-check:after {
  content: ' ';
  display: block;
  background: #f3f3f3;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 27px;
  top: 1px;
  animation: slide 0.2s linear 1;
}
.toggle-check {
  background: #f8f8f8;
  border: 2px solid #ccc;
  border-radius: 25px;
  display: block;
  width: 50px;
  height: 24px;
  position: relative;
}
.toggle-check:hover {
  cursor: pointer;
}
.toggle-check:after {
  content: ' ';
  display: block;
  background: #f3f3f3;
  border-radius: 100%;
  box-shadow: 0 0 0 2px #ccc, 4px 0 5px rgba(0, 0, 0, 0.1);
  width: 22px;
  height: 22px;
  position: absolute;
  left: 1px;
  top: 1px;
  animation: slideback 0.2s linear 1;
}
@keyframes slide {
  0% {
    left: -2px;
  }
  25% {
    width: 30px;
    border-radius: 20px;
  }
  100% {
    left: 24px;
  }
}
@keyframes slideback {
  0% {
    left: 24px;
  }
  25% {
    width: 30px;
    border-radius: 20px;
  }
  100% {
    left: -2px;
  }
}

/* Radio */

.form_0 .radio {
  margin: 0.5rem;
}
.form_0 .radio input[type=radio] {
  position: absolute;
  opacity: 0;
}
.form_0 .radio input[type=radio] + .radio-label:before {
  content: "";
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #cccccc;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.form_0 .radio input[type=radio]:checked + .radio-label:before {
  background-color: #f5ba1a;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.form_0 .radio input[type=radio]:focus + .radio-label:before {
  outline: none;
  border-color: #f5ba1a;
}
.form_0 .radio input[type=radio]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #cccccc;
  background: #cccccc;
}

/* .form_0 .radio input[type=radio] + .radio-label:empty:before {
  margin-right: 0;
} */

.form_0 .radio-label {
  font-size: 14px;
  font-weight: 500;
}
/* checkbox */

.form_0 input[type='checkbox'] {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
  background-color: white;
}

.form_0 label.choice {
  position: relative;
  cursor: pointer;
}

.form_0 label.choice:before {
  content:'';
  background-color: white;
  border: 2px solid #cccccc;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form_0 input[type='checkbox']:checked + label.choice:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #f5ba1a;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.desc{
  padding: 10px;
}

.wrapper_form {
    position: absolute;
    width: 350px;
    left: 30px;
    padding: 25px;
  }
  
  .steps {
    max-width: 300px;
    margin: 0 auto;
  }
  
  .step {
    display: flex;
    position: relative;
  }
  .step:after {
    content: "";
    position: absolute;
    left: 15px;
    top: 32px;
    height: 0;
    width: 2px;
    background-color: #007EA7;
  }
  .step .info {
    margin: 8px 0 20px;
  }
  .step .title {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 8px;
    color: rgb(18, 31, 73);
  }
  .step .text {
    font-size: 12px;
    color: rgba(63, 39, 148, 0.7);
    padding-bottom: 0;
    margin: 0;
  }
  .step:not(:last-child):after {
    height: 75%;
  }
  
  .step .number {
    width: 32px;
    height: 32px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #007EA7;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
    font-weight: 600;
    margin-right: 14px;
  }
  .number.completed {
    background-color: #007EA7;
  }
  .number svg {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
  .number svg path {
    fill: white;
  }

  .input_container {
    border: 1px solid #e5e5e5;
    margin: 5px;
  }


  input[type=file]::file-selector-button {
    background-color: #fff;
    color: #000;
    border: 0px;
    border-right: 1px solid #e5e5e5;
    padding: 10px 15px;
    margin-right: 20px;
    transition: .5s;
  }
  
  input[type=file]::file-selector-button:hover {
    background-color: #eee;
    border: 0px;
    border-right: 1px solid #e5e5e5;
  }
  
  .form_addcard {
    left: 30px;
    opacity: 1;
    padding: 15px;
    position: relative;
    transition: none;
    width: 560px;
    z-index: 1;
  }

  .form_0 {
    /* left: 30px; */
    /* width: 370px; */
    padding: 0 25px;
    /* margin: 8% auto 0; */
    position: relative;
    z-index: 1;
    /* border-top: 5px solid #f5ba1a; */
    -webkit-transition: none;
    transition: none;
    opacity: 1;
  }

.form_pages {
  left: 400px;
  width: 400px;
  padding: 25px;
  /* margin: 8% auto 0; */
  position: relative;
  z-index: 1;
  /* border-top: 5px solid #f5ba1a; */
  -webkit-transition: none;
  transition: none;
  opacity: 1;
}
.form_pages h2 ,.form_0 h2 {
  font-size: 1.5em;
  line-height: 1.5em;
  margin: 0;
}
.form_pages .title_container ,.form_0 .title_container{
  text-align: center;
  /* padding-bottom: 15px; */
}
.form_pages h3 ,.form_0 h3{
  font-size: 1.1em;
  font-weight: normal;
  line-height: 1.5em;
  margin: 0;
}
.form_pages label ,.form_0 label{
  font-size: 12px;
}
.form_pages .row ,.form_0 .row{
  margin: 10px -15px;
}
.form_pages .row > div ,.form_0 .row > div{
  padding: 0 15px;
  box-sizing: border-box;
}
.form_pages .col_half ,.form_0 .col_half{
  width: 50%;
  float: left;
}
.form_pages .input_field ,.form_0 .input_field{
  position: relative;
  /* margin-bottom: 10px; */
}

.form_pages .input_field.active > span,.form_0 .input_field.active > span{
  background-color: #20ce10;
}

.form_pages .input_field > span ,.form_0 .input_field > span{
  position: absolute;
  left: 3px;
  top: 3px;
  color: #333;
  font-size: 18px;
  height: CALC(100% - 6px);
  border-right: 1px solid #cccccc;
  text-align: center;
  width: 30px;
}

.form_pages .input_field > span ,.form_0 .input_field > .span{
  position: absolute;
  left: 0;
  top: 0;
  color: #333;
  height: CALC(100% - 8px);
  border-right: 1px solid #cccccc;
  text-align: center;
  width: 30px;
}

.form_pages .input_field > span > i ,.form_0 .input_field > span > i {
  padding-top: 10px;
}
.form_pages .textarea_field > span > i ,.form_0 .textarea_field > span > i{
  padding-top: 10px;
}
.form_pages input[type=text], .form_pages input[type=email], .form_pages input[type=password],
.form_0 input[type=text], .form_0 input[type=email], .form_0 input[type=password], .form_0 input[type=number], .form_0 select {
  width: 100%;
  padding: 8px 10px 9px 35px;
  height: 35px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  outline: none;
  margin: 3px;
}

.form_pages textarea ,.form_0 textarea {
  width: 100%;
  padding: 8px 10px 9px 35px;
  height: 100px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  outline: none;
  resize: none;
}

.form_pages input[type=text]:hover, .form_pages input[type=email]:hover, .form_pages input[type=password]:hover , .form_pages textarea:hover, .form_pages select:hover,
.form_0 input[type=text]:hover, .form_0 input[type=email]:hover, .form_0 input[type=password]:hover , .form_0 textarea:hover, .form_0 select:hover, .form_0 input[type=number]:hover{
  background: #fafafa;
}

.form_pages input[type=text]:focus, .form_pages input[type=email]:focus, .form_pages input[type=password]:focus, .form_pages textarea:focus, .form_pages select:focus,
.form_0 input[type=text]:focus, .form_0 input[type=email]:focus, .form_0 input[type=password]:focus, .form_0 textarea:focus, .form_0 select:focus, .form_0 input[type=number]:focus {
  -webkit-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  -moz-box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
  border: 1px solid #f5ba1a;
  background: #fafafa;
}


.form_pages input[type=submit], .form_pages button, .form_0 input[type=submit] ,.form_0 button{
  background: #f5ba1a;
  height: 42px;
  /* line-height: 35px; */
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 1.1em;
  margin-bottom: 10px;
}
.form_pages input[type=submit]:hover ,.form_0 input[type=submit]:hover {
  background: #e1a70a;
}
.form_pages input[type=submit]:focus ,.form_0 input[type=submit]:focus{
  background: #e1a70a;
}
/* .form_pages input[type=checkbox], .form_0 input[type=radio] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
} */


.info-panel {
  padding: 1rem;
  background: var(--color-background-info);
  color: var(--color-content-light);
  display: flex;
  align-items: flex-start;
  border-radius: 0.4rem;
  transition: 0.4s;
}

.info-panel p {
  margin-top: 0;
}

.info-panel {
  margin: 1rem;
}
.info-panel__content {
  flex: 1;
  padding: 0 1rem;
}
.info-panel--warning {
  background: var(--color-warning);
}
.info-panel--success {
  background: var(--color-success);
}
.info-panel--pending {
  background: var(--color-pending);
}
.info-panel--norm {
  background: var(--color-norm);
}

.info-panel--closed {
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
  pointer-events: none;
  visibility: hidden;
  height: 0;
  -webkit-animation: height 0.4s linear;
          animation: height 0.4s linear;
}

.info-panel button {
  cursor: pointer;
  border: none;
  background: var(--color-action);
  color: var(--color-content-light);
  padding: 0.6em 1.2em;
  text-decoration: none;
  border-radius: 0.2em;
  font-weight: lighter;
  font-size: inherit;
  line-height: inherit;
  transition: 0.2s;
  box-shadow: inset 0 0 0 2rem transparent;
}
.info-panel button:hover {
  box-shadow: inset 0 0 0 2rem var(--color-hover-tint);
}

.info-panel .icon {
  fill: currentColor;
  width: 2rem;
  height: 2rem;
  font-size: 32px;
  font-weight: 100;
}

btn-tertiary {
  color: #555;
  padding: 0;
  line-height: 40px;
  width: 300px;
  margin: auto;
  display: block;
  border: 2px solid #555;
}
.btn-tertiary:hover, .btn-tertiary:focus {
  color: #888888;
  border-color: #888888;
}

/* input file style */
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file + .js-labelFile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
}
.input-file + .js-labelFile .icon:before {
  content: "";
}
.input-file + .js-labelFile.has-file .icon:before {
  content: "";
  color: #5AAC7B;
}


.userThemaslist{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: 15px; 
  padding-top: 15px;
  /* padding-left: 15px; */
  padding-bottom: 15px;
  align-items: center;
  justify-items: center; /* adjusted */
}

.user_thema {
  cursor: pointer;
  width: 90%;
  height: 140px;
  /* min-height: 100px; */
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  position: relative;
  background-size: 300px;
}

.userThemaLogo {
  position: absolute;
  padding: 0;
  width: 175px;
  height: 175px;
  top: -50px;
  left: -62px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  opacity: 0.3;
}

.AutorBox {
  position: absolute;
  padding: 0;
  width: 300px;
  height: 40px;
  bottom: 15px;
  left: 10px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  opacity: 1;
}

.AutorBox .sLogo,
.AutorIcon .sLogo {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 5px;
  left: 10px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  border-radius: 50%;
  border: 2px solid #ffffff84;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}

.InvertBox {
  position: absolute;
  padding: 0;
  width: 300px;
  height: 40px;
  bottom: 10px;
  left: 30px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  opacity: 1;
}

.AutorIcon {
  position: absolute;
  padding: 0;
  width: 300px;
  height: 40px;
  bottom: 15px;
  left: 170px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  opacity: 1;
}

.invertName {
  display: inline-block;
  position: relative;
  left: 3px;
  top: -8px;
  color: #eee;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  font-size: 24px;
  font-weight: 300;
  padding: 5px;
}

.invertLogo {
  display: inline-block;
  width: 32px;
  height: 32px;
  top: 5px;
  left: 20px;
  user-select: none;
  pointer-events: none;
  border-radius: 50%;
  border: 2px solid #ffffff84;
}

.invertLogo:nth-child(1) {
  margin-left: -10px;
  z-index: 10;
}
.invertLogo:nth-child(2) {
  z-index: 15;
  margin-left: -15px;
}
.invertLogo:nth-child(3) {
  z-index: 20;
  margin-left: -15px;
}
.invertLogo:nth-child(4) {
  z-index: 25;
  margin-left: -15px;
}

.AutorBox .uname,
.AutorIcon .uname {
  position: absolute;
  left: 40px;
  top: 10px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  font-size: 18px;
  padding: 5px;
  color: #eee;
}

.AutorBox .arole,
.AutorIcon .arole {
  position: absolute;
  left: 60px;
  top: 0px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
  color: #eee;
}


.UserBox {
  position: absolute;
  padding: 0;
  width: 300px;
  height: 40px;
  bottom: 15px;
  left: 320px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  opacity: 1;
}

.UserBox .sLogo {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 5px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}

.UserBox .uname {
  position: absolute;
  left: 30px;
  top: 10px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  font-size: 18px;
  padding: 5px;
}

.UserBox .arole {
  position: absolute;
  left: 50px;
  top: 0px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
}