.accordion-item {
    /* margin-bottom: 10px; */
    /* border: 1px solid #ccc; */
    /* border-radius: 5px; */
    overflow: hidden;
    transition: height 0.3s ease;
  }
  
  .accordion-header {
    /* background-color: #f5f5f5; */
    /* padding: 10px; */
    cursor: pointer;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
  }
  
  /* .accordion-content {
    padding: 10px;
  } */
  
  .accordion-item.expanded {
    height: auto;
  }
  
  .accordion-icon {
    font-size: 18px;
  }
  