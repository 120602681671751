/* CARDS */

.rcontainer {
  width: var(--size_card);
  height: var(--size_card);
  -ms-perspective: 800px;
  perspective: 800px;
}

.rotecard {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 6px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

/* .rotecard:hover{
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
} */

.rotecard #divs {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 6px;
  color: var(--text_color_menu);
    background: var(--card_backcolor);
  display: -ms-flexbox;
  display: flex;
  -o-box-pack: center;
  justify-content: center;
  -o-box-align: center;
  align-items: center;
  font: 16px/1.5 "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #47525d;
}

.back {
  transform: rotateY(180deg);
  /* pointer-events: none; */
}
.flipped {
  transform: rotateY(180deg);
}

.ClassRooms {
  display: flex;
}

.grouplist {
  width: 180px;
}

.classlist {
  flex: 7; /* Змінено рядок */
  width: auto;
}

.studentslist {
  width: 300px;
}

.classgrouplist{
  position: relative;
  top: -50px;
  left: 30px;

  /* display:inline-flex;
    flex-wrap:wrap;
    justify-content: space-between; */
    /* padding-left: 15%; */
    /* width: 1fr; */
    display: grid;
    grid-template-rows: repeat(var(--max_colums), var(--size_card));
    /* grid-template-columns: 1fr; */
    grid-template-columns: repeat(var(--max_colums), var(--size_card));
    grid-gap: 15px; 
    /* grid-row-gap:max(var(--space_card), 50px);
    grid-column-gap:max(var(--space_card), 50px); */
    /* calc(var(--space_card) + 25px); */
    /* grid-auto-rows: 75px; */
    padding-top: 15px;
    padding-left: 25px;
    padding-bottom: 15px;
    align-items: center;
    justify-items: center; /* adjusted */
    /* grid-template-columns: repeat(auto-fill, 250px); */
}

.classgroup{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px; 
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-items: center; /* adjusted */
}

.classlevellist{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px; 
    padding-top: 15px;
    padding-left: 25px;
    padding-bottom: 15px;
    align-items: center;
    justify-items: center; /* adjusted */
}

.classDeckslist{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr  1fr  1fr  1fr  1fr ;
  grid-gap: 15px; 
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-items: center; /* adjusted */
}

.classDecklist{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px; 
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-items: center; /* adjusted */
}

.cards {
    /* display:inline-flex;
    flex-wrap:wrap;
    justify-content: space-between; */
    /* padding-left: 15%; */
    /* width: 1fr; */
    display: grid;
    grid-template-rows: repeat(var(--max_colums), var(--size_card));
    /* grid-template-columns: 1fr; */
    grid-template-columns: repeat(var(--max_colums), var(--size_card));
    grid-gap: max(var(--space_card), 50px); 
    /* grid-row-gap:max(var(--space_card), 50px);
    grid-column-gap:max(var(--space_card), 50px); */
    /* calc(var(--space_card) + 25px); */
    /* grid-auto-rows: 75px; */
    padding-top: 45px;
    padding-left: calc((var(--size_card)) / 2);
    padding-bottom: 45px;

    align-items: center;
  
    justify-items: center; /* adjusted */
    /* grid-template-columns: repeat(auto-fill, 250px); */
  }

  .card_students {
    cursor: pointer;
    width: 90%;
    height: 180px;
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    position: relative;
  }

  .card_students .sLogo {
    position: absolute;
    padding: 8px;
    width: 55px;
    height: 55px;
    top: 5px;
    left: 5px;
    border-radius: 30%; /* Встановіть радіус закруглення на половину ширини або висоти, щоб отримати круглу форму */
    object-fit: cover; /* Забезпечте, щоб фото повністю покривало область, задану шириною та висотою */

  }

  .card_students .sTitle {
    position: absolute;
    top: 8px;
    left: 80px;
    font-weight: 600;
    font-size: 18px;
    color: var(--text_color_form);
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
}

.card_students .sEmail {
  position: absolute;
  top: 35px;
  left: 80px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}

.card_students .sA1 {
  position: absolute;
  top: 54px;
  left: 80px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}

.card_students .sA2 {
  position: absolute;
  top: 54px;
  left: 130px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}

.card_students .sA3 {
  position: absolute;
  top: 54px;
  left: 190px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}

.card_students .sId {
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}

.card_students .sStatus {
  position: absolute;
  bottom: 65px;
  left: 15px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}

.card_students .sLastin {
  position: absolute;
  bottom: 45px;
  left: 15px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}

.card_students .sDate {
  position: absolute;
  bottom: 85px;
  left: 15px;
  font-weight: 400;
  font-size: 12px;
  color: var(--text_color_form);
}


  .card_group {
    cursor: pointer;
    width: 90%;
    height: 120px;
    /* min-height: 100px; */
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    background-size: 300px;
  }

  .card_group .gTitle {
      position: absolute;
      top: 5px;
      left: 15px;
      font-weight: 600;
      font-size: 42px;
      color: var(--text_color_form);
  }

  .card_group .gLabel {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 16px;
    color: var(--text_color_form);
  }

  .card_group .gLevel {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: 100%;
    font-size: 16px;
    color: var(--text_color_form);
  }

  .card_group .gTime {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 16px;
    color: var(--text_color_form);
  }

  .card_group .gNameclass {
    position: absolute;
    top: 40px;
    right: 15px;
    font-size: 18px;
    font-weight: 600;
    color: var(--text_color_form);
  }

  .card3 {
    cursor: pointer;
    width: 90%;
    height: 240px;
    /* min-height: 100px; */
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    background-size: 300px;
  }

  .card_deck {
    cursor: pointer;
    width: 90%;
    height: 140px;
    /* min-height: 100px; */
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    background-size: 300px;
  }

  .lLogo {
    position: absolute;
    padding: 10px;
    width: 75px;
    height: 75px;
    top: 5%;
    left: 5%;
    cursor: pointer;
  }

  .dcLogo {
    position: absolute;
    padding: 0;
    width: 375px;
    height: 375px;
    top: -80px;
    left: -135px;
    cursor: pointer;
    user-select: none;
    pointer-events: none;
    opacity: 0.3;
  }

  .dcFlagLogo {
    position: absolute;
    padding: 0;
    width: 75px;
    height: 75px;
    top: 40px;
    left: 30%;
    border-radius: 50%; /* зробіть картинку круглою, встановивши радіус бордюра на половину ширина/висота */
    border: 2px solid #fff; /* обведення */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* тінь */
    user-select: none;
    pointer-events: none;
  }

  .llblTitle {
    position: absolute;
    top: 25px;
    left: 130px;
    width: 100%;
    font-size: 16px;
    color: var(--text_color_form);
  }

  .dclbl01 {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 40%;
    font-size: 12px;
    color: var(--card_backcolor);
    opacity: 0.3;
    text-align: left;
  }

  .dclbl02 {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 40%;
    font-size: 12px;
    color: var(--card_backcolor);
    text-align: right;
  }

  .dclbl03 {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 100%;
    font-size: 18px;
    font-weight: 100;
    text-align: right;
    color: var(--text_color_form);
  }
  .dclbl03b {
    position: absolute;
    top: 80px;
    right: 10px;
    width: 100%;
    font-size: 18px;
    font-weight: 100;
    text-align: right;
    color: var(--text_color_form);
  }

  .dclbl04 {
    position: absolute;
    top: 55px;
    right: 10px;
    width: 100%;
    font-size: 32px;
    text-align: right;
    color: var(--text_color_form);
  }

  .dclbl04b {
    position: absolute;
    top: 130px;
    right: 0;
    width: 100%;
    font-size: 32px;
    text-align: center;
    color: var(--text_color_form);
  }

  .dclbl05 {
    position: absolute;
    top: 35px;
    right: 10px;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    text-align: right;
    color: var(--text_color_form);
  }

  .dclbl05b {
    position: absolute;
    top: 180px;
    right: 0;
    width: 100%;
    font-size: 16px;
    font-weight: 100;
    text-align: center;
    color: var(--text_color_form);
  }

  .dclbl06 {
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 38px;
    font-weight: 600;
    text-align: center;
    color:  #ff9500;
    /* background-color: rgb(100, 100, 100); */
    border-radius: 10px;
  }
  
  .lTitle {
    position: absolute;
    top: 50px;
    left: 130px;
    width: 100%;
    font-size: 32px;
    color: var(--text_color_form);
  }

  .lNote {
    position: absolute;
    top: 95px;
    left: 130px;
    width: 100%;
    font-size: 14px;
    color: #8f8f8f;
  }

  .LabelID {
    position: absolute;
    top: 10px;
    left: 3px;
    width: 100%;
    writing-mode: vertical-lr; /* lr significa de izquierda a derecha */
    text-orientation: mixed; /* Permite que los caracteres se orienten correctamente */
    white-space: nowrap; /* Opcional: evita el salto de línea si el texto es demasiado largo */
    font-size: 14px;
    font-weight: 600;
    color: #ff9500;
  }

  .BTNplay {
    position: absolute;
    bottom:  15px;
    right: 15px;
    font-size: 14px;
    color: #8f8f8f;
  }

  .task__levelCard--0 {
    color: #414141;
    background-color: #c4cad3;
  }

  .task__levelCard--1 {
    color: #05341d;
    background-color: #d6ede2;
  }

  .task__levelCard--2 {
    color: #ff8d01;
    background-color: #ffe3c0;
  }

  .task__levelCard--3 {
    color: #a734ba;
    background-color: #f2dcf5;
  }

  .task__levelCard--4 {
    color: #2d86ba;
    background-color: #ceecfd;
  }

  .task__levelCard {
    border-radius: 100px;
    padding: 2px 13px;
    font-size: 12px;
    position: absolute;
    bottom: 15px;
    right: 50px;
  }

  .task__levelUser {
    border-radius: 100px;
    padding: 2px 13px;
    font-size: 12px;
    position: absolute;
    bottom: 15px;
    right: 15px;
  }

  .SideA {
    position: absolute;
    top: 15px;
    left: 35px;
    width: 42%;
    font-size: 14px;
    color: #8f8f8f;
  }

  .SideA img {
    width: 100px;
  }

  .SideB {
    position: absolute;
    top: 15px;
    left: 55%;
    width: 42%;
    font-size: 14px;
    color: #8f8f8f;
  }

  .LabelNumber {
    position: absolute;
    bottom: 10px;
    left: 3px;
    width: 100%;
    writing-mode: vertical-lr; /* lr significa de izquierda a derecha */
    text-orientation: mixed; /* Permite que los caracteres se orienten correctamente */
    white-space: nowrap; /* Opcional: evita el salto de línea si el texto es demasiado largo */
    font-size: 14px;
    font-weight: 600;
    color: #ff9500;
  }

  .card2 {
    width: var(--size_card);
    height: var(--size_card);
    /* min-height: 100px; */
    border-radius: 5px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    background-size: 300px;
  }
  


  .cLogo {
    position: absolute;
    padding: 10px;
    width: calc(100% /2);
    height: calc(100% /2);
    top: 10%;
    left: 20%;
    cursor: pointer;

    /* min-height: 100px; */
  }

  .cButton {
    position: absolute;
    top: -5px;
    right: 8px;
    font-size: 22px;
    cursor: pointer;
    color: var(--card_text_color);
  }

  .cButtonFlip{
    position: absolute;
    top: -5px;
    left: 8px;
    font-size: 22px;
    cursor: pointer;
  }

  .cTitle {
    position: absolute;
    top: 70%;
    left: 0px;
    width: 100%;
    font-size: calc( var(--size_card) / 10);
    text-align: center;
    color: var(--text_color_form);
  }
  
  .cNote {
    position: absolute;
    top: 84%;
    left: 0px;
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: #8f8f8f;
  }

  .toolspanel{
    position: absolute;
      bottom: -130px;
      left: 3px;
      width: calc(100% - 6px );
      height: 70px;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      display: flex;
      align-items: center;
      /* justify-content: center; */
      opacity: 0;
      overflow: hidden;
      display:none;
      border-radius: 8px;
  }

  /* .toolspanel .title{
    position: absolute;
    left: 8px;
    top: -10px;
    font-size: 22px;
  }

  .toolspanel .label{
    position: absolute;
    font-size: 12px;
    left: 8px;
    top: 35px;
    width: 100%;
    margin: 0px;
    padding: 0;
  } */

  .toolspanel .btnbox{
    position: absolute;
    display: flex;
    top: 0px;
    left: 30px;
  }

  .toolspanel .btnboxR{
    position: absolute;
    display: flex;
    top: 0px;
    right: 8px;
  }

  .card_list:hover .toolspanel{
    opacity: 1;
    bottom: 3px;
    display:block;
  }

  .aButtonCardActions {
    width: 40px;
    height: 40px;
    padding: 8px;
    font-size: 32px;
    cursor: pointer;
  }

  .aButtonCardActions:hover {
    color: #ff9500;
  }

  .editSideA {
    position: relative;
    padding: 8px;
  }

  .editSideB {
    position: relative;
    padding: 8px;
  }

  .editSideC {
    position: relative;
    padding: 8px 32px;
  }

  .editorMDsider {
    position: relative;
    top: 3px;
    left: 30px;
    margin-bottom: 15px;
  }

  .card {
    margin: 10px;
    padding: 10px;
    width: 180px;
    height: 180px;
    /* min-height: 100px; */
    display: grid;
    grid-template-rows: 10px 30px 50px 20px;
    border-radius: 5px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
  }

  .card_list {
    cursor: pointer;
    width: 90%;
    height: 300px;
    /* min-height: 100px; */
    border-left: #ff9500 solid 5px;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    position: relative;
    background-color: rgb(255, 255, 255);
  }
  
  .card:hover, .card2:hover, .card3:hover, .card_list:hover, .card_group:hover , .card_students:hover{
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
    background-color: rgba(0, 0, 0, 0.06);
  }
  
  .card__link,
  .card__date,
  .card__title {
    position: relative;
    text-decoration: none;
    color: #ff9500;
  }
  
  .card__link::after {
    position: absolute;
    top: 25px;
    left: 0;
    content: "";
    width: 0%;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s;
  }
  
  .card__link:hover::after {
    width: 100%;
  }
  
  .card__date {
    grid-row: 1 / span 1;
    font-size: 10px;
    justify-self: end;
  }
  
  .card__title {
    grid-row: 2 / span 1;
    font-size: 24px;
    font-weight: 600;
  }
  
  .card__nota {
    grid-row: 3 / span 1;
    font-weight: 400;
    font-size: 12px;
    color: #ff9500;
  }
  
  .card__apply {
    grid-row: 4 / span 1;
    font-size: 12px;
    align-self: center;
    color: #ff9500;
  }
  
  /* CARD BACKGROUNDS */
  
  .card-1 {
    color: var(--text_color_menu);
    background: var(--card_backcolor);
  }
  
  .card-2 {
    background: radial-gradient(#816c70, #723f4c);
  }
  
  .card-3 {
    background: radial-gradient(#76b2fe, #b69efe);
  }
  
  .card-4 {
    background: radial-gradient(#60efbc, #58d5c9);
  }
  
  .card-5 {
    background: radial-gradient(#f588d8, #c0a3e5);
  }

  @media (max-width: 5000px) {
    .classgroup{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }
  }

  @media (max-width: 3000px) {
    .classgroup{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }
  }

  @media (max-width: 2700px) {
    .classgroup{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }
  }

  @media (max-width: 2400px) {
    .classDeckslist{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }

    .classgroup{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }
  }

  @media (max-width: 1700px) {
    .classDeckslist{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr  1fr  1fr 1fr 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }

    .classgroup{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }
  }
  @media (max-width: 1200px) {
    .classDeckslist{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr  1fr  1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }

    .classgroup{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }

    .classlevellist{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr ;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-left: 25px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }

    .classDecklist{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }
  }

  @media (max-width: 1000px) {
    .classgroup{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }
  }
  @media (max-width: 800px) {
    .classDeckslist{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr  1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }

    .classgroup{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }

    .classlevellist{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-left: 25px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }

    .classDecklist{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }
  }

  @media (max-width: 600px) {

    .classDeckslist{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr ;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }

    .classgroup{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }

    .classlevellist{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-left: 25px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }

    .classDecklist{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }
  }

  @media (max-width: 300px) {

    .classDeckslist{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr ;
      grid-gap: 15px; 
      padding-top: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      align-items: center;
      justify-items: center; /* adjusted */
    }
  }
  