.index_board_project{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px; 
    padding:15px;
    align-items: center;
    justify-items: center; /* adjusted */
}

.index_card_project_list .title {
  position: absolute;
  top: 10px;
  left: 100px;
  width: calc(100% - 100px);
  font-size: 32px;
  text-align: left;
  color: white;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.numID {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
  color: white;
}

.index_card_project_list .note {
  position: absolute;
  top: 60px;
  left: 100px;
  width: 70%;
  font-size: 14px;
  text-align: center;
  padding: 0px;
  margin: 0px;
  color: white;
}

.index_card_project_list {
    cursor: pointer;
    width: 100%;
    height: 260px;
    /* min-height: 100px; */
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    background-size: 300px;
  }
  
  .index_card_project_list:hover{
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
    background-color: rgba(0, 0, 0, 0.06);
  }

  .prlogo { 
    grid-area: logo; 
    width: 80px;
    padding: 10px;
    position: absolute;
    left: 10px;
    top: 5px;
    color: var(--card_text_color);
  }

  @media (max-width: 7680px) {
    .index_board_project{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }
  }

  @media (max-width: 5120px) {
    .index_board_project{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }
  }

  @media (max-width: 3840px) {
    .index_board_project{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }
  }

  @media (max-width: 3440px) {
    .index_board_project{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
  }

  @media (max-width: 2560px) {
    .index_board_project{
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
  }

  @media (max-width: 1920px) {
    .index_board_project{
        grid-template-columns: 1fr 1fr 1fr;
      }
  }

  @media (max-width: 1200px) {
    .index_board_project{
        grid-template-columns: 1fr 1fr;
      }
  }

  @media (max-width: 700px) {
    .index_board_project{
        grid-template-columns: 1fr;
      }
  }