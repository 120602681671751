
.uploadfilelist {
    list-style-type: none;
    margin: 0px;
    padding: 5px;
}

.uploadfilelist .lisinmarker {
    display: list-item;
    text-align: -webkit-match-parent;
    margin: 3px;
}

.uploadfilelist .lisinmarker.active > .userlist{
  color: #f5ba1a;
  background-color: #818181;
}


.uploadfilelist .userlist{
    overflow: hidden;
    position: relative;
    width: 99%;
    margin: 0px;
    padding: 0px;
    background-color: hsl(0, 0%, 95%);
    height: 45px;
    z-index: 0;
    border-radius: 5px;
}

.uploadfilelist .linenumero{
    position: absolute;
    text-align: center;
    z-index: 1;
    font-size: 14px;
    left: 0px;
    top: 0px;
    width: 20px;
    margin: 10px;
}

.uploadfilelist .reference{
    position: absolute;
    text-align: left;
    z-index: 1;
    font-size: 14px;
    left: 30px;
    top: 3px;
    width: 80%;
    margin: 10px;
}

.uploadfilelist .delfile {
    position: absolute;
    text-align: center;
    z-index: 1;
    right: 0px;
    top: 0px;
    font-size: 16px;
    width: 30px;
    margin: 10px;
}

.uploadfilelist .delfile:hover {
    color: #f5ba1a;
}

.uploadfilelist .userlist:hover,
.v-container .leftbaritem.home:hover{
    background-color: hsla(0, 0%, 85%, 0.568);
}

.form_upload {
    width: 100%;
    padding: 0px;
    /* margin: 8% auto 0; */
    position: relative;
    z-index: 1;
    /* border-top: 5px solid #f5ba1a; */
    -webkit-transition: none;
    transition: none;
    opacity: 1;
    display: inline-block;
  }

  .v-container {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 13%;
    justify-content:space-between;
    position: fixed;
  }

  .tab-container {
    display: flex;
    flex-direction:row;
    /* margin: 1rem; */
    /* width: 100%; */
    justify-content:space-between;
    position: relative;
  }

  .v-container .leftbaritem.active , .tab-container .leftbaritem.active{
    color: var(--menu_backcolor);
    background-color: green;
    width: 100%;
  }

  .v-container .leftbaritem.home , .tab-container .leftbaritem.home{
    width: 100%;
    background-color: #f5ba1a00;
  }

  .v-container .leftbarboldtext , .tab-container .leftbarboldtext{
    /* font-weight: 700; */
    font-size: 17px;
    display: inline-block;
    vertical-align: middle; /* Alinea verticalmente los elementos */
  }

  .v-container p, .tab-container p{
    margin: 0;
    padding: 5px;
  }

  .divider {
    /* margin-left:18px; */
    width: 0px;
    background: lightgrey;
    position: relative;
    left:15%;
  }

  .h-container {
    display: flex;
    height: 90vh;
  }

 .leftbaritem:hover {
    background-color: #818181;
  }

.leftbaritem{ 
	color:#292929;
	background-color:#d6d6d6;
    outline: none;
    width: 100%;
    height: 35px;
	padding:0px;
	text-transform:uppercase;
  margin: 5px;
	border-radius:3px;
	cursor:pointer;
    position:relative;
    opacity: 1;
}

.right-container {
    width: 25%;
    padding: 0;
    margin: 1rem;
    position: fixed;
    right:45px;
  }

.main-container {
    width: 55%;
    padding: 0;
    margin: 1rem;
    position: relative;
    left:15%;
}

 /* TABLA LIST CARDS */

 .listcards {
    display: list-item;
    text-align: -webkit-match-parent;
    margin: 0px;
    padding: 0px;
    height: 31px;
  }

  .listcards:hover + .listcards .linenumero,
  .listcards:hover + .listcards .reference,
  .listcards:hover + .listcards .datatime,
  .listcards:hover + .divider  {    
    background:rgb(203, 240, 203);
  }

  .listcards .linenumero {
    display:inline-block;
    color:#333;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    padding: 3px;
    width: 5%;
    text-align: center;
    overflow: hidden; /* Oculta el texto que excede el contenedor */
  }

  .listcards .type {
    display:inline-block;
    border-radius: 3px;
    padding: 3px;
    margin: 1px;
    width: 15%;
    text-align: center;
    overflow: hidden; /* Oculta el texto que excede el contenedor */
  }

  .listcards .reference {
    display:inline-block;
    color:#333;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    padding: 3px;
    margin: 1px;
    width: 14%;
    text-align: center;
    overflow: hidden; /* Oculta el texto que excede el contenedor */
  }


  .listcards .datatime {
    display:inline-block;
    color:#333;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    padding: 3px;
    margin: 1px;
    width: 60%;
    text-align: left;
    white-space: nowrap; /* Evita que el texto se divida en múltiples líneas */
    overflow: hidden; /* Oculta el texto que excede el contenedor */
    text-overflow: ellipsis; /* Agrega puntos suspensivos (...) al final del texto truncado */
  }

  .listcards .cb_0{
    color: var(--menu_backcolor);
    background-color: green;
  }

  .listcards .cb_1{
    color: orangered;
  }

  .listcards .cb_2{
    color:var(--text_color_menu);
  }

  .listcards .cb_3{
    color: green;
  }

  .listcards .cb_4{
    color: blue;
  }


 .form_container {
    /* left: 10px; */
    opacity: 1;
    /* padding: 3px; */
    position: relative;
    transition: none;
    z-index: 1;
  }